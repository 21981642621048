.workflowDetailsPage {
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 1240px;
  margin: 0 auto;
  font-family: "Poppins";
  font-style: normal;
  min-height: calc(100vh - 170px);

  .navigationArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .backContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .backButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .backText {
          margin-left: 16px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          @include themify($themes) {
            color: themed("primaryTextColor");
          }
        }
      }
    }
    .buttonsArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .name-container {
    padding: 20px;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include themify($themes) {
      background-color: themed("secondaryBackgroundColor");
    }

    .left {
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        margin-bottom: 6px;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      .workflowName {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        max-width: 800px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      input {
        width: 780px;
        margin-top: 5px;
        font-size: 24px;
        font-weight: 600;
        outline: none;
        border: none;
        border-bottom: 1px solid $gray;
        display: block;

        @include themify($themes) {
          color: themed("primaryTextColor");
          background-color: themed("secondaryBackgroundColor");
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      .icon {
        margin-right: 10px;
      }
      .viewHistory {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #81b9f9;
      }
    }
  }
  .triggerType {
    margin-top: 24px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;

    @include themify($themes) {
      color: themed("primaryTextColor");
      background-color: themed("secondaryBackgroundColor");
    }

    .titleArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .trigger-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      button {
        margin-left: auto;
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        color: #fd7062;
        font-size: 14px;
        font-weight: 500;
        font-family: "Poppins";
      }
    }

    .trigger-row {
      margin-top: 16px;
      display: flex;

      .card {
        cursor: pointer;
        display: flex;
        flex: 1;

        &:nth-child(1) {
          margin-right: 20px;
        }

        img {
          height: 48px;
          width: 48px;
        }

        .column {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 16px;
            font-weight: 600;
          }

          .subtitle {
            font-size: 12px;
            font-weight: 500;
            color: $gray;
          }
        }
      }
      .unselected {
        opacity: 0.7;
        cursor: auto;
      }
    }

    .trigger-container {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      .horizontalLine {
        width: 100%;
        height: 1px;
        margin: 10px 0;

        @include themify($themes) {
          background: themed("secondaryTextColor");
        }
      }

      .scheduled {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        font-family: "Poppins";

        label[for="time"] {
          margin-top: 24px;
        }

        select {
          border: none;
          outline: none;
          width: 254px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 500;
          padding: 14px 20px;
          margin-top: 6px;
          font-family: "Poppins";
          appearance: none;
          background-image: url("../../assets/icons/down-arrow.svg");
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%;
          background-size: 0.65rem auto;

          @include themify($themes) {
            background: themed("primaryInputFieldBackground");
            color: themed("primaryTextColor");
          }

          &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;

            border-top: 8px solid #f00;
          }
        }

        div {
          display: flex;
          align-items: center;
          margin-top: 6px;

          input[type="number"] {
            border: none;
            outline: none;
            padding: 12px 0;
            border-radius: 8px;
            width: 50px;
            margin: 0 10px;
            box-sizing: border-box;
            text-align: center;
            font: inherit;

            @include themify($themes) {
              background: themed("primaryInputFieldBackground");
              color: themed("primaryTextColor");
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              display: none;
            }

            -moz-appearance: textfield;
          }

          input[type="time"] {
            margin-left: 10px;
            border: none;
            outline: none;
            padding: 13px 20px;
            border-radius: 8px;
            font-family: "Poppins";
            @include themify($themes) {
              background: themed("primaryInputFieldBackground");
              color: themed("primaryTextColor");
            }
          }

          input[type="time"]::-webkit-calendar-picker-indicator {
            background: none;
          }

          select {
            margin-top: 0;
            margin-left: 10px;
          }
        }
        .bigText {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          @include themify($themes) {
            color: themed("primaryTextColor");
          }
        }
      }

      .webhook {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;

        .subtitle {
          font-size: 12px;
          color: #adaeb6;
          margin-top: 4px;
        }

        & > div {
          border-radius: 8px;
          margin-top: 16px;
          display: flex;
          align-items: center;

          @include themify($themes) {
            background: themed("primaryInputFieldBackground");
            color: themed("primaryTextColor");
          }

          div {
            padding: 14px 20px;
            border-radius: 8px 0 0 8px;

            @include themify($themes) {
              background: themed("secondaryInputFieldBackground");
              color: themed("primaryTextColor");
            }
          }

          input {
            margin: 0 16px;
            flex: 1;
            background-color: inherit;
            outline: none;
            border: none;
            font: inherit;
            @include themify($themes) {
              background: themed("primaryInputFieldBackground");
              color: themed("primaryTextColor");
            }
          }
        }
      }
    }
  }

  .deploymentMethodsContainer {
    margin-top: 24px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;

    @include themify($themes) {
      color: themed("primaryTextColor");
      background-color: themed("secondaryBackgroundColor");
    }
    .deploymentTitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }

    .card {
      width: 400px;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      box-sizing: border-box;
      margin: 20px 20px 0 0;
      border-radius: 8px;
      cursor: pointer;
      @include themify($themes) {
        background: themed("primaryInputFieldBackground");
      }

      .cardTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 12px;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px;

        .icon {
          margin-right: 14px;
        }
        .blueText {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          @include themify($themes) {
            color: themed("primaryBlue");
          }
        }
      }
    }
  }

  .workflowHistoryContainer {
    margin: 24px 0;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    display: block;

    @include themify($themes) {
      color: themed("primaryTextColor");
      background-color: themed("secondaryBackgroundColor");
    }
    .titleRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .historyTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      .historySubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #adaeb6;
      }
    }

    .mainRow {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 20px 20px 0 0;

        .row {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .icon {
            margin-right: 10px;
          }
          .title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
          }
          .green {
            @include themify($themes) {
              color: themed("primaryGreen");
            }
          }
          .red {
            color: #fd7062;
          }
        }
        .subtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #adaeb6;
        }
      }
    }
    .failedProcessOnly {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .switch-container {
        cursor: pointer;

        @include custom-switch();

        span {
          color: #adaeb6;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .tableContainer {
      border-radius: 8px;
      width: 100%;
      margin-top: 20px;
      overflow: hidden;
      position: relative;

      @include themify($themes) {
        background: themed("primaryBackgroundColor");
        border: themed("borderPrimary");
      }

      .tableHeading {
        width: 100%;
        display: flex;
        align-items: center;
        @include themify($themes) {
          background: themed("primaryInputFieldBackground");
        }

        .head {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          padding: 14px;
          @include themify($themes) {
            color: themed("primaryTextColor");
          }
        }
        .one {
          width: 5%;
        }
        .two {
          width: 20%;
        }
        .three {
          width: 15%;
        }
        .four {
          width: 20%;
        }
        .five {
          width: 30%;
        }
      }
      .tableBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        .tableRow {
          width: 100%;
          display: flex;
          align-items: center;

          @include themify($themes) {
            color: themed("primaryTextColor");
            background: themed("secondaryBackgroundColor");
            border-top: themed("borderPrimary");
          }
          &:hover {
            @include themify($themes) {
              background: themed("primaryBackgroundColor");
            }
          }

          .row {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            padding: 14px;
            display: flex;
            align-items: center;
            @include themify($themes) {
              color: themed("primaryTextColor");
            }

            .view,
            .re-run,
            .ignore {
              display: flex;
              align-items: center;
              margin-right: 40px;
              cursor: pointer;

              .icon {
                margin-right: 10px;
              }
            }
            .view {
              color: #adaeb6;
            }
            .re-run {
              @include themify($themes) {
                color: themed("primaryBlue");
              }
            }
            .ignore {
              color: #fd7062;
            }
          }
          .one {
            width: 5%;

            .icon {
              width: 20px;
              height: 20px;
            }
          }
          .two {
            width: 20%;
          }
          .three {
            width: 15%;
          }
          .four {
            width: 20%;
          }
          .five {
            width: 30%;
          }
        }
        .emptyText {
          text-align: center;
          color: #adaeb6;
          margin: 50px 0;
        }
      }
      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        .spinner {
          &::before {
            @include themify($themes) {
              background: themed("secondaryBackgroundColor");
            }
          }
        }
      }
    }
    .loadingTable {
      opacity: 0.7;
    }
    .filterAndPaginationContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 30px 0;
      color: #adaeb6;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      .filter {
        display: flex;
        align-items: center;

        .select {
          margin-left: 10px;
          display: grid;
          grid-template-areas: "select";
          align-items: center;
          position: relative;

          select,
          &::after {
            grid-area: select;
          }

          &::after {
            content: "";
            justify-self: end;
            width: 0.8rem;
            height: 0.5rem;
            background-color: #adaeb6;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            margin-right: 12px;
          }

          select {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            border-radius: 8px;
            padding: 12px 28px 12px 12px;
            outline: none;
            appearance: none;
            cursor: pointer;
            color: #adaeb6;
            border: none;
            @include themify($themes) {
              background: themed("primaryInputFieldBackground");
            }
          }
        }
      }
      .pagination {
        display: flex;
        .leftArrow {
          cursor: pointer;
          margin-right: 30px;
        }
        .previousPageNumber,
        .nextPageNumber,
        .pageNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 16px;
          border-radius: 8px;
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
          margin-right: 20px;
          color: #ffffff;

          @include themify($themes) {
            background: themed("primaryBlue");
          }
        }
        .previousPageNumber,
        .nextPageNumber {
          cursor: pointer;
          color: #adaeb6;

          @include themify($themes) {
            background: themed("primaryInputFieldBackground");
          }
        }
        .pageNumber {
          color: #ffffff;

          @include themify($themes) {
            background: themed("primaryBlue");
          }
        }
        .rightArrow {
          cursor: pointer;
        }
      }
    }
  }
  .hideHistory {
    display: none;
  }

  .dropdown-item {
    position: relative;

    .icon {
      position: absolute;
      top: 13px;
      left: 10px;
      color: #ffffff;
      transform: rotate(180deg);
      height: 12px;
      width: 12px;
      transition: 0.2s all ease-in-out;
      z-index: 0;
      cursor: pointer;
    }
    .activeIcon {
      transform: rotate(270deg);
    }

    .dropdown-btn {
      cursor: pointer;
      margin-left: 10px;
      border: none;
      padding: 10px 20px;
      background: transparent;
      color: #fff;
      font-size: 14px;
      font-weight: 600;

      & + .dropdown-btn {
        margin-left: 10px;
        border: none;
        padding: 10px 20px;
        background: transparent;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .dropdown-menu {
      margin-left: 10px;
    }
  }

  .code-header {
    margin-top: 42px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-top: 30px;
    border-top: 1px solid #adaeb66e;

    img {
      height: 24px;
      width: 24px;
    }

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    button {
      border: none;
      font-size: 14px;
      font-weight: 600;
      color: $menu-blue;
      padding: 10px;
      background: inherit;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .rowContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .customCodeLibrary {
      width: 420px;
      margin-right: 20px;
      border: 1px solid #adaeb67c;
      height: 340px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .titleArea {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #adaeb67c;
        height: 45px;
        .title {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 21px;
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          border-bottom: 1px solid #3e3d40;
        }
        .activeTitle {
          color: #81b9f9;
          border-bottom: 1px solid #81b9f9;
        }
      }
      .bodyArea {
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        height: inherit;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #adaeb6;
        }
        &::-webkit-scrollbar-thumb {
          background: #575759;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #575759;
          transform: scaleX(0.9);
        }
      }
    }
  }

  .testCodeArea {
    height: 100%;
    margin-bottom: 30px;
  }

  .code-area {
    margin-top: 7px;
    padding: 20px;
    background-color: #2d2d31;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .codeContainer {
      overflow-y: auto;
      height: 300px !important;
      width: inherit;
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #adaeb6;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #3e3d40;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #3e3d40;
        transform: scaleX(1.1);
      }
    }

    #inputArea {
      background: inherit;
      color: #fff;
      resize: none;
      flex: 1;
    }

    button {
      border: none;
      border-radius: 8px;
      color: #fff;
      background: $blue;
      float: right;
      padding: 10px;
      font-weight: 600;
      cursor: pointer;
      font-family: "Poppins";
      margin: 40px 0;
    }

    textarea {
      overflow-y: auto;
      white-space: pre;
      outline: none;
      counter-reset: line;
    }

    .editor #codeArea {
      outline: none;
      padding-left: 60px !important;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .editor pre {
      padding-left: 60px !important;
    }

    .editor .editorLineNumber {
      position: absolute;
      left: 0px;
      color: #cccccc3a;
      text-align: right;
      width: 40px;
      font-weight: 100;
    }
  }
  .permission-title {
    margin-top: 42px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
    border-top: 1px solid #adaeb66e;
  }
  .permission-subtitle {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #adaeb6;
  }
  .permission-switch {
    margin-top: 24px;
    display: flex;
    align-items: center;

    .switch-container {
      @include custom-switch();
      margin-left: 0;

      label {
        input {
          &:checked + span {
            background: #8ce28b;
          }
        }
      }
    }

    > span {
      font-size: 16px;
      font-weight: 600;

      > span {
        color: $menu-blue;
      }
    }
  }

  .people {
    margin-top: 10px;
    margin-left: 40px;
    border-radius: 8px;
    background: #4d4d51;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      height: 37px;
      width: 37px;
      background: rgba(31, 144, 255, 0.2);
      color: $blue;
      font-size: 16px;
      font-weight: 700;
    }

    & .count + .tab {
      margin-left: 16px;
    }

    .tab {
      border-radius: 8px;
      padding: 8px 12px;
      background: #6b6b70;
      color: #adaeb6;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      & + .tab {
        margin-left: 10px;
      }

      button {
        margin-left: 8px;
        outline: none;
        border: none;
        color: #fff;
        background: inherit;
        font-size: 16px;
        cursor: pointer;
      }
    }

    input {
      margin-left: 10px;
      border: none;
      outline: none;
      color: #fff;
      background: inherit;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .people-msg {
    margin-left: 40px;
    color: #adaeb6;
    font-size: 12px;
    font-weight: 500;
  }

  .people {
    margin-top: 10px;
    border-radius: 8px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      height: 37px;
      width: 37px;
      background: rgba(31, 144, 255, 0.2);
      color: $blue;
      font-size: 16px;
      font-weight: 700;
    }
    .tab {
      border-radius: 16px;
      padding: 8px 12px;
      background: #6b6b70;
      color: #adaeb6;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-left: 16px;

      button {
        margin-left: 8px;
        outline: none;
        border: none;
        color: #fff;
        background: inherit;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
