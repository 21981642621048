.dashboardPageLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #3e3d40;
  overflow: auto;
  position: relative;

  padding: 0 20px 0 2px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      color: #81b9f9;
      margin-bottom: 26px;
    }
    .add-btn {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background-color: #1f90ff;
      border-radius: 8px;
      border: none;
      outline: none;

      span {
        margin-left: 8px;
        color: #fff;

        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .body {
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;

    .app-card {
      height: 200px;
      width: 200px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $white;
      border-radius: 16px;
      padding: 0 20px 20px 20px;
      box-sizing: border-box;
      font-weight: 600;
      cursor: pointer;
      background-color: #575759;
      transition: 0.2s all ease-in-out;
      &:hover {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
          0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
      }

      .menu {
        text-align: right;
        font-size: 24px;
        cursor: pointer;
        letter-spacing: 2px;
        width: 100%;
      }
      img {
        max-width: 100%;
        max-height: 75px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;
      }
      .app-title {
        text-align: center;
        font-size: 18px;
      }
    }
  }
}

.showAddAppModal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
}
.addAppContainer {
  position: fixed;
  width: 450px;
  height: 550px;
  background-color: #3e3d40;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  .scrollable-container {
    overflow: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #adaeb6;
    }
    &::-webkit-scrollbar-thumb {
      background: #575759;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #575759;
      transform: scaleX(0.9);
    }
    &::-webkit-scrollbar-corner {
      background: #2d2d31;
    }

    @keyframes expand {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .titleArea {
      margin-bottom: 20px;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        color: $white;
      }
    }
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .inputGroup {
        display: flex;
        flex-direction: column;

        label {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }

        input,
        textarea {
          background: #575759;
          font-weight: 600;
          font-size: 14px;
          outline: none;
          border: none;
          margin: 8px 8px 8px 0;
          box-sizing: border-box;
          border-radius: 8px;
          height: 50px;
          padding: 12px;
          line-height: 18px;
          width: 100%;

          color: $white;
          &::placeholder {
            color: #adaeb6;
            opacity: 1;
            font-weight: 500;
          }

          :-ms-input-placeholder {
            color: #adaeb6;
          }

          ::-ms-input-placeholder {
            color: #adaeb6;
          }
        }
        textarea {
          resize: none;
          height: 100px;
          white-space: pre;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      border-radius: 8px;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      width: 100%;
      height: 50px;
      cursor: pointer;
    }
    .submit {
      background: #1f90ff;
      color: #fff;
      margin-bottom: 20px;
    }
    .cancel {
      background-color: #e3e3e6;
      color: #424451;
    }
  }
}
