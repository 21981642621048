.processDetailsInfoPage {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    min-height: calc(100vh - 170px);

    .titleText {
        margin-right: 23px;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        display: flex;
        align-items: center;
        color: $white;
    }
    .headerContainer {
        height: 100px;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .titleArea {
            display: flex;
            align-items: center;

            .iconArea {
                margin-right: 22px;
                cursor: pointer;
            }
        }

        .greyText {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #adaeb6;
            padding-left: 16px;
            border-left: 1px solid #4244518f;
        }

        .actionsArea {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .re-run {
                display: flex;
                align-items: center;
                margin-right: 30px;

                img {
                    height: 20px;
                    width: 20px;
                }

                span {
                    margin-left: 10px;
                    color: $blue;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .ignore {
                display: flex;
                align-items: center;

                img {
                    height: 18px;
                    width: 18px;
                }

                span {
                    margin-left: 10px;
                    color: #fd7062;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
    .InformationContainer {
        width: 100%;
        .InformationDetails {
            margin: 30px 0 0 0;
            border-radius: 8px;

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .greyText {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: #adaeb6;
                    margin-bottom: 10px;
                }

                .actionsArea {
                    flex: 1;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .re-run {
                        display: flex;
                        align-items: center;
                        margin-right: 30px;

                        img {
                            height: 20px;
                            width: 20px;
                        }

                        span {
                            margin-left: 10px;
                            color: $blue;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    .ignore {
                        display: flex;
                        align-items: center;

                        img {
                            height: 18px;
                            width: 18px;
                        }

                        span {
                            margin-left: 10px;
                            color: #fd7062;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .informationTitle {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: #adaeb6;
                margin-bottom: 10px;
            }
            .detailsContainer {
                background: #2d2d31;
                border-radius: 8px;
                width: calc(100% - 60px);
                height: 432px;
                padding: 30px;
                overflow-y: auto;
                color: $white;

                &::-webkit-scrollbar {
                    width: 6px;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #adaeb6;
                }
                &::-webkit-scrollbar-thumb {
                    background: #575759;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #575759;
                    transform: scaleX(0.9);
                }

                .errorMessage {
                    background-color: #fd7062;
                    padding: 10px 4px;
                    border-radius: 4px;
                    color: $white;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;

                    .infoIcon {
                        margin: 0 8px;
                    }
                }
            }
            .contractDetailsContainer {
                background: #2d2d31;
                border-radius: 8px;
                height: 100%;
                width: calc(100% - 60px);
                padding: 30px;

                .contractText {
                    white-space: pre-wrap;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: $white;
                }
            }
        }
    }
}
