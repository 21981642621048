.workflowRuleModal {
    position: fixed;
    width: 600px;
    height: 300px;
    background-color: #3e3d40;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: expand 0.2s ease-in-out;
    border-radius: 18px;
    padding: 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
        0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

    @keyframes expand {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .processDetailsInfoPage {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .titleText {
            margin-right: 23px;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            display: flex;
            align-items: center;
            color: $white;
        }

        .InformationContainer {
            width: 100%;
            .InformationDetails {
                margin: 30px 0 0 0;
                border-radius: 8px;
                height: 100%;

                .informationTitle {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: #adaeb6;
                    margin-bottom: 10px;
                }

                .contractDetailsContainer {
                    background: #2d2d31;
                    border-radius: 8px;
                    height: 130px;
                    width: calc(100% - 60px);
                    padding: 30px;

                    .contractText {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: $white;
                    }
                }
            }
        }
    }
}
