@import "./font";
@import "./variables/colors";
@import "./variables/theme-variables.scss";
@import "./mixins";
@import "./components/navbar";
@import "./components/sidebar3";
@import "./components/workflowRuleModal";
@import "./components/historyDetail";
@import "./components/confirmationModal";
@import "./components/pageTitle";
@import "./components/appCard";
@import "./components/clientCard";
@import "./components/commonAppSettings";
@import "./components/slackAppSettings";
@import "./components/mailchimpAppSettings";
@import "./components/shopifyAppSettings";
@import "./components/footer";
@import "./pages/layout";
@import "./pages/login";
@import "./pages/signup";
@import "./pages/legacy-workflow";
@import "./pages/profile";
@import "./pages/create-workflow-legacy";
@import "./pages/password";
@import "./pages/settings";
@import "./pages/users";
@import "./pages/history";
@import "./pages/billings";
@import "./pages/forgotPassword";
@import "./pages/hire";
@import "./pages/processDetails";
@import "./pages/processDetailsInfo";
@import "./pages/dashboard";
@import "./pages/appsOffered";
@import "./pages/appsSettings";
@import "./pages/myApps";
@import "./pages/workflows";
@import "./pages/create-workflow";
@import "./pages/myClients";
@import "./pages/workflowHistory";
@import "./pages/workflowDetailsPage";
@import "./pages/myAppsIntegration";
@import "./pages/campminderIntegrationPortal";
@import "./pages/sharpspringIntegrationPortal";
@import "./pages/orders";
@import "./pages/createOrder";
@import "./pages/consult";

// @import '~bootstrap/scss/bootstrap';
* {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}
.theme-dark,
.theme-light {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.err {
  color: #fd7062;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 6px 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner {
    height: 56px;
    width: 56px;
    box-sizing: border-box;
    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(39, 174, 96, 0) 0deg,
      rgba(31, 144, 255, 0) 0.04deg,
      #1f90ff 360deg
    );
    border-radius: 56px;
    animation: 1s rotate infinite linear;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      border-radius: 48px;
      @include themify($themes) {
        background: themed("primaryBackgroundColor");
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      width: 8px;
      background: $blue;
      border-radius: 8px;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .text {
    color: #adaeb6;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }
}

.primaryButton {
  font-family: "Poppins";
  font-style: normal;
  padding: 8px 16px;
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border-radius: 6px;

  @include themify($themes) {
    background: themed("primaryBlue");
  }
}

.borderedButton {
  font-family: "Poppins";
  font-style: normal;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border-radius: 6px;

  @include themify($themes) {
    background-color: themed("secondaryBackgroundColor");
    color: themed("primaryBlue");
    border: themed("buttonBorder");
  }
}
.secondaryBorderedButton {
  font-family: "Poppins";
  font-style: normal;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border-radius: 6px;
  background: transparent !important;

  @include themify($themes) {
    color: themed("primaryTextColor");
    border: themed("borderPrimary");
  }
}
.fullWidth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.primaryInputField {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &::placeholder {
    @include themify($themes) {
      color: themed("secondaryTextColor");
    }
  }

  @include themify($themes) {
    background: themed("primaryInputFieldBackground");
    color: themed("primaryTextColor");
  }
}
.triggerIcon {
  transition: all 0.2s ease-in-out;
}

.DateRangePicker__MonthHeaderLabel {
  @include themify($themes) {
    color: themed("primaryTextColor");
  }
}

.DateRangePicker__WeekdayHeading abbr[title] {
  @include themify($themes) {
    color: themed("primaryTextColor");
  }
}

.DateRangePicker__Date {
  border: none;
}
.DateRangePicker__Month {
  border: none;
  @include themify($themes) {
    color: themed("primaryTextColor");
  }
}
.DateRangePicker__Date--weekend {
  @include themify($themes) {
    background: themed("secondaryInputFieldBackground");
    color: themed("primaryTextColor");
  }
}

.DateRangePicker__MonthDates {
  border: none;
}
.DateRangePicker__CalendarSelection {
  @include themify($themes) {
    background: themed("primaryBlue");
    border: themed("buttonBorder");
  }
}
.DateRangePicker__CalendarHighlight--single {
  border: none;
}
