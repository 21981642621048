.preferences {
  text-decoration: none;
  width: 276px;
  margin: 30px 30px 0 0;

  @media screen and (max-width: 992px) {
    height: auto;
    border-right: none;
    border-bottom: 1px solid rgba(175, 181, 187, 0.3);
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0 24px 0 0;
    padding: 0;
    list-style-type: none;

    @media screen and (max-width: 992px) {
      flex-direction: row;
      align-items: center;
    }

    .list-unstyled {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    a {
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s all ease-in-out;

      &:hover {
        color: #81b9f9;
      }

      &:active {
        color: #81b9f9;
        background: rgba(129, 185, 249, 0.1);
      }
    }
    .selected-btn {
      background-color: rgba(129, 185, 249, 0.1);
      color: #81b9f9;
    }
  }
}
