.consultPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 210px);
  height: 100%;

  @include themify($themes) {
    color: themed("primaryTextColor");
  }

  .backContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .backButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .backText {
        margin-left: 16px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
    }
  }

  .titleArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;

      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
    .subtitle {
      margin-top: 6px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }
  }

  .methods {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 12px 0 24px 0;
    box-sizing: border-box;

    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }

    .consultType {
      cursor: pointer;
      min-width: 300px;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 8px;
      margin: 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .icon {
        margin-bottom: 16px;
      }
      .type {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      .subtitle {
        margin-top: 6px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        @include themify($themes) {
          color: themed("secondaryTextColor");
        }
      }
      @include themify($themes) {
        background: themed("secondaryBackgroundColor");
        border: themed("borderPrimary");
      }
      &:hover {
        @include themify($themes) {
          background: themed("primaryBackgroundColor");
          border: themed("borderPrimary");
        }
      }
    }

    .selectedConsultType {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      @include themify($themes) {
        border: themed("buttonBorder");
      }
      &:hover {
        @include themify($themes) {
          border: themed("buttonBorder");
          background: themed("primaryBackgroundColor");
        }
      }
    }
  }

  .optionArea {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 300px;
    height: 100%;
    margin-top: 30px;
    padding: 30px;
    box-sizing: border-box;
    overflow: hidden;

    .zoomContainer {
      opacity: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 30px;
      left: -100%;
      transform: translateX(-50%);
      transition: all 0.2s ease-in-out;

      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }

      .calendly-widget {
        transform: scale(0.8);
        margin-top: -90px;
      }
    }
    .showZoomContainer {
      left: 50%;
      opacity: 1;
    }

    .phoneContainer {
      overflow: hidden;
      opacity: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 10px;
      right: -100%;
      transform: translateX(50%);
      padding: 30px;
      box-sizing: border-box;
      border-radius: 8px;
      transition: all 0.2s ease-in-out;

      @include themify($themes) {
        background: themed("secondaryBackgroundColor");
      }

      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      .secondaryInputField {
        width: 600px;
        border: none;
        outline: none;
        border-radius: 8px;
        padding: 10px 20px;
        box-sizing: border-box;
        margin: 14px 0 20px 0;
        display: flex;
        align-items: center;
        height: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
          @include themify($themes) {
            color: themed("secondaryTextColor");
          }
        }

        @include themify($themes) {
          background: themed("primaryInputFieldBackground");
          color: themed("primaryTextColor");
        }
      }
      .phoneNumberArea {
        margin-top: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .selectInputArea {
          display: grid;
          grid-template-areas: "select";
          align-items: center;
          position: relative;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;

          @include themify($themes) {
            background: themed("secondaryInputFieldBackground");
          }

          .countryCodeValue {
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            color: #adaeb6;
            cursor: pointer;
          }

          select,
          &::after {
            grid-area: select;
          }

          &::after {
            content: "";
            justify-self: end;
            width: 0.8rem;
            height: 0.5rem;
            background-color: #adaeb6;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            margin-right: 12px;
          }

          .select {
            width: 90px;
            height: 50px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            padding: 12px 24px 12px 12px;
            outline: none;
            appearance: none;
            cursor: pointer;
            z-index: 1;
            opacity: 0;

            @include themify($themes) {
              background: themed("backgroundShadeSecondary");
              color: themed("backgroundShadeSecondary");
            }

            &:disabled {
              cursor: not-allowed;
              color: #adaeb6;
            }
          }
        }
        .phoneNumberField {
          width: 300px;
          border: none;
          outline: none;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 10px 20px;
          box-sizing: border-box;
          height: 50px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          &::placeholder {
            @include themify($themes) {
              color: themed("secondaryTextColor");
            }
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          .phoneNumberField[type="number"] {
            -moz-appearance: textfield;
          }

          @include themify($themes) {
            background: themed("primaryBackgroundColor");
            color: themed("primaryTextColor");
          }
        }
      }

      .submitButton {
        font-family: "Poppins";
        font-style: normal;
        padding: 8px 16px;
        box-sizing: border-box;
        border: none;
        outline: none;
        cursor: pointer;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        border-radius: 6px;
        margin-top: 24px;

        @include themify($themes) {
          background: themed("primaryBlue");
        }
      }
    }
    .showPhoneContainer {
      right: 50%;
      opacity: 1;
    }
  }
}
