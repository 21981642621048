.clientCard {
  background: #575759;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 310px;
  padding: 18px 20px;
  box-sizing: border-box;
  cursor: pointer;

  .clientName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 6px;
  }
  .appNumbers {
    margin: 0 0 16px 0;
    padding: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #adaeb6;
  }

  .appsContainer {
    display: flex;
    align-items: center;
    .imageContainer {
      width: 36px;
      height: 36px;
      background: #3e3d40;
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 20px;
        height: 20px;
      }
      .moreApp {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #81b9f9;
      }
    }
  }
}
