$themes: (
  light: (
    navbarBackgroundColor: #ffffff,
    primaryTextColor: #3e3d40,
    secondaryTextColor: #adaeb6,

    primaryBackgroundColor: #fafafa,
    secondaryBackgroundColor: #ffffff,

    backgroundShade: #fafafa,
    backgroundShadeSecondary: #ebeced,

    scrollbarTrack: #d7d7db,
    scrollbarThumb: #adaeb6,

    primaryInputFieldBackground: #fafafa,
    secondaryInputFieldBackground: #e3e3e680,
    inputFieldText: #424451,

    primaryBlue: #1f90ff,
    secondaryBlue: #e3f1ff,
    buttonBorder: 1px solid #1f90ff,
    navbarBorder: 3px solid #1f90ff,
    navbarInActiveBorder: 3px solid #ffffff,

    primaryYellow: #e58e26,
    secondaryYellow: #fcf2e7,

    themeSwitchBackground: #ffd873,
    themeSwitchHandle: #ffffff,

    settingsButtonColor: #2d2d31,

    requestBorder: 1px solid #ffffff,
    hoverPrimaryBackground: #f5f5f5,

    borderPrimary: 1px solid #e3e3e6,
    primaryGreen: #51bf50,

    backgroundOverlay: #fff,
  ),
  dark: (
    navbarBackgroundColor: #2d2d31,
    primaryTextColor: #ffffff,
    secondaryTextColor: #adaeb6,

    primaryBackgroundColor: #3e3d40,
    secondaryBackgroundColor: #4f4e52,

    backgroundShade: #575759,
    backgroundShadeSecondary: #404043,

    scrollbarTrack: #adaeb6,
    scrollbarThumb: #575759,

    primaryInputFieldBackground: #3e3d40,
    secondaryInputFieldBackground: #2d2d31,
    inputFieldText: #ffffff,

    primaryBlue: #81b9f9,
    secondaryBlue: rgba(129, 185, 249, 0.1),
    buttonBorder: 1px solid #81b9f9,
    navbarBorder: 3px solid #81b9f9,
    navbarInActiveBorder: 3px solid #2d2d31,

    primaryYellow: #f4edab,
    secondaryYellow: rgba(228, 222, 165, 0.3),
    themeSwitchBackground: #81b9f9,
    themeSwitchHandle: #2d2d31,

    settingsButtonColor: #81b9f9,

    requestBorder: 1px solid #4f4e52,
    hoverPrimaryBackground: #2a2a2c,

    borderPrimary: 1px solid #adaeb677,

    primaryGreen: #8ce28b,

    backgroundOverlay: #000,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      transition: all 0.2s ease-in-out;
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
