.signup {
  height: 100%;
  width: 100%;
  display: flex;

  .left-container {
    @include login-left-container();
  }
  .right-container {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3e3d40;
    overflow-y: auto;
    padding: 30px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #adaeb6;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #3e3d40;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #3e3d40;
      transform: scaleX(1.1);
    }

    .form-container {
      width: 75%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 30px;
        font-weight: 800;
        color: $white;
        margin: 10px 0 22px 0;
      }
      .basic-details-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 6px;

        .inputArea {
          margin-bottom: 14px;

          input {
            width: 100%;
            border: none;
            outline: none;
            border-radius: 8px;
            padding: 10px 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            height: 50px;
            background-color: #575759;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #fff;

            &::placeholder {
              color: #c8cad2;
            }
          }
        }

        div {
          flex: 0 0 48%;
          color: $dark;
          font-weight: 600;

          label {
            display: block;
            margin-bottom: 6px;
            font-size: 14px;
            color: $white;
            font-weight: 500;
          }
          span {
            @include login-input-field();
            display: inline-flex;
            width: 100%;

            input {
              margin-left: 0;
            }
          }
        }
        & > :not(:first-child):not(:nth-child(2)) {
          margin-top: 14px;
        }
      }
      .borderLine {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #afb5bb48;
      }
      button {
        @include login-btn();
      }
      .signup-link {
        color: $gray;
        font-size: 16px;
        font-weight: 600;
        margin: 22px 0;
        text-align: center;

        span {
          color: $blue;
          cursor: pointer;
        }
      }
      .copyrightContainer {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .copyright-msg {
          color: $gray;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}
