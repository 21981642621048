/*-----------------------------------------------------------
    Pagination
----------------------------------------------------------*/

.pagination {
  border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-left-radius: 0px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-right-radius: 0px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #777777;
  background-color: #f5f5f5;
  border-color: #eaeaea;
}
.pagination > li > a,
.pagination > li > span {
  color: #777777;
  border: 1px solid #eaeaea;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.pager li > a,
.pager li > span {
  border: 1px solid #eaeaea;
}
.pager li > a:hover,
.pager li > a:focus {
  background-color: #f5f5f5;
}
.pager.primary li > a,
.pager.primary li > span {
  border: 1px solid $primary-color;
  color: #ffffff;
  background-color: $primary-color;
}
.pager.primary li > a:hover,
.pager.primary li > a:focus {
  background-color: rgba(0, 131, 122, 1);
}

.pagination > li > a,
.pagination > li > span {
  padding: 15px;
}
.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-item:last-child .page-link,
.pagination-sm .page-item:first-child .page-link,
.pagination-sm .page-item:last-child .page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0px;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #555555;
}
.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: $primary-color;
  border-color: $primary-color;
}
