#edbridge-calendar td {
  width: 100%;
}
.calendarHeader {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.calendar-box {
  width: 100%;
  min-height: 75px;
  text-align: start;
  justify-content: start;
  flex-direction: column;
}
.dayStr {
  font-size: small;
}
