.appCard {
  background: #575759;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
  height: 74px;
  padding: 18px 20px;
  box-sizing: border-box;

  .rowContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 160px;

    .icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .appTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .button {
    border-radius: 8px;
    outline: none;
    border: none;
    width: 100px;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .buttonIcon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }
  .integrate {
    background: #81b9f9;
  }
  .disconnect {
    background: #fd7062;
  }
}
