.myAppsPage {
  font-family: "Poppins";
  color: #ffffff;
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #adaeb6ac;
    margin: 30px 0 0 0;
  }
  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    .leftMenu {
      width: 220px;
      height: 100%;
      padding: 20px 20px 20px 0;

      display: flex;
      flex-direction: column;

      .label {
        font-weight: 600;
        font-size: 14px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        line-height: 16px;
        color: #adaeb6;
        margin: 10px 0;
        padding: 8px;
      }
      .menuItem {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
      }
      .active {
        color: #81b9f9;
        background: rgba(129, 185, 249, 0.1);
      }
    }
    .rightContent {
      flex: 1;
      border-left: 1px solid #adaeb6ac;
      height: 100%;
      min-height: 600px;
      width: 100%;
      padding: 30px 0 30px 30px;

      .category {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        .label {
          font-weight: 600;
          font-size: 20px;
          line-height: 16px;
          color: #adaeb6;
          padding: 0;
          margin: 0 0 16px 0;
        }

        .appsContainer {
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-auto-rows: 70px;
        }
        .emptyApp {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 80px;

          .folderIcon {
            margin-bottom: 10px;
          }
          .emptyAppText {
            font-family: "Poppins";
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #adaeb6;
            margin: 16px 0 10px 0;
          }
          .emptyAppSubtitleText {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #adaeb6;
            margin: 0 0 20px 0;
            padding: 0;
            opacity: 0.5;
          }
          .button {
            cursor: pointer;
            outline: none;
            border: none;
            background: #1f90ff;
            border-radius: 4px;
            padding: 12px 24px;

            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
