/*-----------------------------------------------------------
    Badges
----------------------------------------------------------*/

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #fff;
  background-color: #777;
  border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0 5px;
}
.badge.badge-md {
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 15px;
  -o-border-radius: 15px;
  -ms-border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
.badge.badge-lg {
  font-size: 17px;
  padding: 7px 15px;
  border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}
.badge .i {
  font-size: 12px;
}
.badge.badge-md .i {
  font-size: 14px;
}
.badge.badge-lg .i {
  font-size: 17px;
}
.badge-secondary {
  background-color: #9e9e9e;
  color: #ffffff;
}
.badge-primary {
  background-color: $primary-color;
  color: #ffffff;
}
.badge-purple {
  background-color: $purple-color;
  color: #ffffff;
}
.badge-accent {
  background-color: $accent-color;
  color: #ffffff;
}
.badge-info {
  background-color: $info-color;
  color: #ffffff;
}
.badge-warning {
  background-color: $warning-color;
  color: #ffffff;
}
.badge-danger {
  background-color: $danger-color;
  color: #ffffff;
}
.badge-success {
  background-color: $success-color;
  color: #ffffff;
}
.badge-light {
  color: #555555;
  background-color: #f5f5f5;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: $primary-color;
}
.nav-pills > li > a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 7px 15px;
}
.uibadges .badge {
  margin: 5px 5px 0 0;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: $primary-dark-color;
}
.badge-accent[href]:hover,
.badge-accent[href]:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: $accent-dark-color;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: $info-dark-color;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: $warning-dark-color;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: $danger-dark-color;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: $success-dark-color;
}

.ui-badges {
  .badge {
    margin: 0 10px 10px 0;
  }
}
