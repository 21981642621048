.processDetailsPage {
    background-color: #3e3d40;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 20px;
    min-height: calc(100vh - 170px);

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #adaeb6;
    }
    &::-webkit-scrollbar-thumb {
        background: #575759;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #575759;
        transform: scaleX(0.9);
    }
    .headerContainer {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .titleArea {
            display: flex;
            align-items: center;
        }

        .titleText {
            margin-right: 23px;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            display: flex;
            align-items: center;
            color: #fff;
        }

        .row {
            display: flex;
            align-items: center;
        }
        .iconArea {
            cursor: pointer;
            position: relative;
            margin-bottom: 5px;

            .menu {
                position: absolute;
                height: 106px;
                width: 203px;
                top: 0;
                right: -20px;
                z-index: 2;
                background: #424451;
                border-radius: 8px;

                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 20px;

                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #fff;

                .update,
                .delete {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    border-radius: 8px;
                    width: 100%;
                    height: 50px;
                    padding-left: 10px;

                    &:hover {
                        background-color: #767a94;
                    }

                    span {
                        margin-left: 8px;
                    }
                }
            }
            .menu-bottom {
                top: -106px;
            }
        }

        .switchArea {
            margin-left: 30px;
            .switch-container {
                @include custom-switch();
            }
        }
    }

    .processStatusContainer {
        padding: 10px 0;

        .spaceBetweenContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .statusTitle {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: $light-gray;
            }
            .primaryButton {
                font-weight: 800;
                font-size: 14px;
                line-height: 18px;
                color: #ffffff;

                background: #1f90ff;
                border-radius: 8px;
                border: none;
                outline: none;
                cursor: pointer;
                padding: 12px 24px;
            }
        }

        .statusContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #575759;
            border-radius: 8px;

            .rightContainer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .column {
                margin: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .row {
                    display: flex;
                    align-items: center;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 34px;

                    .icon {
                        margin-right: 6px;
                    }

                    .title {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                    }
                    .green {
                        color: #8ce28b;
                    }
                    .yellow {
                        color: #fede6a;
                    }
                    .red {
                        color: #fd7062;
                    }
                }
                .subtitle {
                    padding: 0;
                    margin: 6px 0 0 0;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #adaeb6;
                }
            }
            .startAlign {
                align-items: flex-start;
                .grayText {
                    opacity: 0.5;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                }
                .whiteText {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .processHistory {
        padding: 20px 0;
        .historyTitleArea {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: $light-gray;
                margin-bottom: 10px;
            }
        }
        .historyContainer {
            background: #575759;
            border-radius: 8px;
            min-height: 270px;
            width: 100%;
            padding-bottom: 20px;
            position: relative;
            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 21px;

                .totalRuns {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #adaeb6;
                    .icon {
                        margin-right: 10px;
                    }
                }
                .failedProcessOnly {
                    width: 190px;
                    .switch-container {
                        cursor: pointer;
                        @include custom-switch();

                        span {
                            color: $light-gray;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                }
            }
            .accordion {
                background: #575759;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #afb5bb53;

                &:last-child {
                    border-bottom: none;
                }

                .header {
                    display: flex;
                    align-items: center;
                    padding: 20px 30px 20px 20px;
                    cursor: pointer;
                    transition: 0.1s ease-in-out;
                    &:hover {
                        background: #38373a;
                    }

                    img {
                        height: 34px;
                    }

                    .identifierSection,
                    .trigger {
                        display: flex;
                        flex-direction: column;
                        padding: 0 20px;
                        border-right: 1px solid $silver-rgba-30;

                        .title {
                            font-size: 12px;
                            font-weight: 600;
                            color: $gray;
                            margin-bottom: 7px;
                        }

                        .sub-title {
                            font-size: 14px;
                            font-weight: 600;
                            color: $white;
                        }
                    }
                    .identifierSection {
                        max-width: 330px;
                        width: 100%;
                    }
                    .trigger {
                        width: 150px;
                    }
                    .dateAndTimeSection {
                        display: flex;
                        flex-direction: column;
                        padding: 0 20px;

                        .title {
                            font-size: 12px;
                            font-weight: 600;
                            color: $gray;
                            margin-bottom: 7px;
                        }

                        .sub-title {
                            font-size: 14px;
                            font-weight: 600;
                            color: $white;
                        }
                    }
                    .actionsArea {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .re-run {
                            display: flex;
                            align-items: center;
                            margin-right: 30px;

                            img {
                                height: 20px;
                                width: 20px;
                            }

                            span {
                                margin-left: 10px;
                                color: $blue;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }

                        .ignore {
                            display: flex;
                            align-items: center;

                            img {
                                height: 18px;
                                width: 18px;
                            }

                            span {
                                margin-left: 10px;
                                color: #fd7062;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .emptyText {
                text-align: center;
                color: #adaeb6;
                margin-top: 50px;
            }
        }
    }
    .filterAndPaginationContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 30px 0;
        color: $light-gray;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        .filter {
            display: flex;
            align-items: center;

            .select {
                margin-left: 10px;
                display: grid;
                grid-template-areas: "select";
                align-items: center;
                position: relative;

                select,
                &::after {
                    grid-area: select;
                }

                &::after {
                    content: "";
                    justify-self: end;
                    width: 0.8rem;
                    height: 0.5rem;
                    background-color: $light-gray;
                    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
                    margin-right: 12px;
                }

                select {
                    border: 1px solid #2d2d31;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    border-radius: 8px;
                    padding: 12px 28px 12px 12px;
                    outline: none;
                    appearance: none;
                    cursor: pointer;
                    color: $light-gray;
                    background-color: #2d2d31;
                }
            }
        }
        .pagination {
            display: flex;
            .leftArrow {
                cursor: pointer;
                margin-right: 30px;
            }
            .previousPageNumber,
            .nextPageNumber,
            .pageNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                background: #1f90ff;
                border-radius: 8px;
                font-weight: 800;
                font-size: 14px;
                line-height: 18px;
                color: #ffffff;
                margin-right: 20px;
            }
            .previousPageNumber,
            .nextPageNumber {
                background-color: #2d2d31;
                color: $light-gray;
                cursor: pointer;
            }
            .pageNumber {
                background: #1f90ff;
                color: #ffffff;
            }
            .rightArrow {
                cursor: pointer;
            }
        }
    }
}
