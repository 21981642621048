.hirePage {
    background-color: #3e3d40;
    height: 100%;
    width: 100%;
    display: flex;

    .leftContainer {
        width: 55%;
        height: 100%;

        .headerContainer {
            display: flex;
            align-items: center;
            margin-bottom: 26px;

            .backContainer {
                margin-right: 20px;
                cursor: pointer;
                height: 30px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title {
                font-weight: 700;
                font-size: 40px;
                line-height: 60px;
                color: #81b9f9;
            }
        }
        .bodyContainer {
            width: calc(100% - 50px);

            .label {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
                margin-bottom: 8px;

                .icon {
                    margin-left: 10px;
                }
            }
            .gray {
                color: #adaeb6;
            }
            .subtitle {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }
            .inputField {
                margin-top: 8px;
                background: #575759;
                border-radius: 8px;
                border: none;
                outline: none;
                height: 50px;
                width: 100%;
                padding-left: 20px;
                color: #fff;

                &::placeholder {
                    color: #c8cad2;
                }
            }
            .horizontalLine {
                height: 1px;
                width: 100%;
                background-color: #afb5bb52;
                margin: 24px 0;
                padding-right: 20px;
            }
            .changePaymentOption {
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #81b9f9;
                padding-bottom: 8px;
                text-decoration: underline;
                cursor: pointer;
            }
            .addPaymentMethod {
                margin-top: 16px;
                width: 100%;
                height: 72px;
                background: rgba(31, 144, 255, 0.1);
                border: 1px dashed #1f90ff;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #1f90ff;
                cursor: pointer;

                .icon {
                    margin-right: 8px;
                }
            }
            .cc-row {
                margin-top: 8px;
                display: flex;
                align-items: center;
                color: $white;
                font-size: 14px;
                height: 60px;
                padding-right: 20px;
                width: calc(100%);
                background: #575759;
                border-radius: 8px;

                .leftRow {
                    display: flex;
                    align-items: center;
                    img {
                        margin-left: 20px;
                        height: 26px;
                    }
                    .name {
                        margin-left: 20px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ffffff;
                    }
                }

                .number {
                    margin-left: auto;
                    font-weight: 700;
                }
            }
            .quotationContainer {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $white;
                font-size: 14px;
                height: 120px;
                padding-right: 20px;
                width: calc(100%);
                background: #575759;
                border-radius: 8px;

                .column {
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;

                    .greyText {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #adaeb6;
                    }
                    .price {
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 48px;
                        color: #8ce28b;
                        span {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                }
            }
            .errorMessage {
                color: #fd7062;
                text-align: center;
                padding: 0;
                margin: 20px 0 -10px 0;
            }
            .row {
                margin-top: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .submitButton {
                    background: #1f90ff;
                    border-radius: 16px;
                    padding: 14px 30px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 30px;
                    color: #ffffff;
                }
                .disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
    .rightContainer {
        width: 55%;
        height: 100%;
        display: flex;
        position: relative;

        .verticalLine {
            background-color: #575759;
            margin-right: 30px;
            width: 1px;
            height: 100%;
        }

        .emptyContainer {
            width: 100%;
            background: #575759;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .howItWorks {
                font-weight: 600;
                font-size: 32px;
                line-height: 48px;
                color: #ffffff;
                margin: 40px auto 20px auto;
            }
            .howItWorksImage {
                transform: scale(0.75);
                margin-top: -80px;
            }
        }
    }
}
