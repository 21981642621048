.historyDetailModal {
    position: fixed;
    width: 820px;
    height: 590px;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: expand 0.2s ease-in-out;
    border-radius: 18px;
    padding: 30px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

    @include themify($themes) {
        background-color: themed("secondaryBackgroundColor");
        color: themed("primaryTextColor");
    }

    @keyframes expand {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .historyDetailsInfoPage {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .titleText {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;

            @include themify($themes) {
                color: themed("primaryTextColor");
            }
        }
        .InformationContainer {
            width: 100%;
            .InformationDetails {
                margin: 30px 0 0 0;
                border-radius: 8px;

                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .greyText {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #adaeb6;
                        margin-bottom: 10px;
                    }

                    .actionsArea {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .re-run {
                            display: flex;
                            align-items: center;
                            margin-right: 30px;
                            cursor: pointer;

                            img {
                                height: 20px;
                                width: 20px;
                            }

                            span {
                                margin-left: 10px;
                                font-size: 14px;
                                font-weight: 600;
                                @include themify($themes) {
                                    color: themed("primaryBlue");
                                }
                            }
                        }

                        .ignore {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img {
                                height: 18px;
                                width: 18px;
                            }

                            span {
                                margin-left: 10px;
                                color: #fd7062;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .blackContainer {
                    padding: 20px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    @include themify($themes) {
                        background: themed("backgroundShadeSecondary");
                    }
                }

                .detailsContainer {
                    width: 100%;
                    height: 450px;
                    padding-right: 20px;
                    overflow: auto;
                    box-sizing: border-box;
                    @include themify($themes) {
                        color: themed("primaryTextColor");
                        background: themed("backgroundShadeSecondary");
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                        height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 10px;
                        @include themify($themes) {
                            background: themed("scrollbarTrack");
                        }
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        @include themify($themes) {
                            background: themed("scrollbarThumb");
                        }
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        transform: scaleX(0.9);
                        @include themify($themes) {
                            background: themed("scrollbarThumb");
                        }
                    }
                    &::-webkit-scrollbar-corner {
                        @include themify($themes) {
                            background: themed("backgroundShadeSecondary");
                        }
                    }

                    .errorMessageContainer {
                        background-color: #fd7062;
                        border-radius: 8px;
                        padding: 10px 4px;
                        box-sizing: border-box;
                        color: $white;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        margin: 0 0 10px 0;

                        .infoIcon {
                            margin: 0 8px;
                        }
                    }
                }
            }
        }
    }
}
