/*-----------------------------------------------------------
    Breadcrumbs
----------------------------------------------------------*/

.breadcrumb {
  background-color: transparent;
  border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}
.breadcrumb,
.breadcrumb a {
  color: #777777;
}
.breadcrumb .i {
  font-size: 14px;
  margin-right: 5px;
}
.breadcrumb.bg {
  background-color: #f5f5f5;
}
.breadcrumb.border {
  border: 1px solid #eaeaea;
  background-color: transparent;
}
.breadcrumb.primary,
.breadcrumb.primary a,
.breadcrumb.primary li.active {
  color: $primary-color;
}
.breadcrumb.auto-hidden li a.collapsed {
  width: 50px !important;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.breadcrumb.auto-hidden li a {
  width: auto;
  white-space: nowrap;
  display: inline-block;
}
.breadcrumb > li,
.breadcrumb li a {
  vertical-align: top;
}
