.workflow-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  .head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray;
    padding-bottom: 32px;
    margin-bottom: 14px;

    & > img {
      height: 32px;
      width: 32px;
      margin-bottom: 20px;
    }
    .column {
      margin-left: 18px;
      display: flex;
      flex-direction: column;

      span {
        &:nth-child(1) {
          font-size: 32px;
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
          color: $gray;
        }
      }
    }
    .create {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      img {
        height: 32px;
        width: 32px;
      }
      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #81b9f9;
      }
      .iconContainer {
        background-color: red;
      }
    }
  }
  .workflowContainer {
    flex: 1;
    height: 80%;
    width: 100%;

    .workflow {
      border-radius: 8px;
      width: 100%;
      margin-bottom: 16px;
      padding: 20px 30px;
      box-sizing: border-box;

      transition: all 0.2s ease-in-out;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 20px;
          width: 40px;
          height: 40px;
        }

        .textArea {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;

            @include themify($themes) {
              color: themed("primaryTextColor");
            }
          }
          .verticalSeparator {
            width: 1px;
            height: 30px;
            margin: 0 20px;

            @include themify($themes) {
              background: themed("secondaryTextColor");
            }
          }
          .subtitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            width: 500px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include themify($themes) {
              color: themed("secondaryTextColor");
            }
          }
        }
      }

      @include themify($themes) {
        background-color: themed("secondaryBackgroundColor");
        border: themed("requestBorder");
        &:hover {
          background-color: themed("hoverPrimaryBackground");
        }
      }

      &:hover {
        border: 1px solid #f2d749;
      }

      .rightContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .column {
          margin: 0 20px;
          display: flex;
          align-items: center;

          .row {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;

            .icon {
              margin-right: 6px;
              height: 18px;
              width: 18px;
            }

            .title {
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
            }
            .green {
              color: #8ce28b;
            }
            .yellow {
              color: #fede6a;
            }
            .red {
              color: #fd7062;
            }
          }
          .subtitle {
            padding: 0;
            margin-left: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #adaeb6;
          }
        }
      }
      img {
        height: 18px;
        width: 18px;
      }
    }
  }

  .emptyWorkflowContainer {
    flex: 1;
    height: 60%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .emptyWorkflowComponent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .emptyIcon {
        margin-bottom: 10px;
      }

      .emptyTitle {
        margin-bottom: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #c8cad2;
      }
      .emptySubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #c8cad2;
      }
    }
  }
  .card {
    padding: 26px 30px;
    background-color: #575759;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .column {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      .subtitle-row {
        display: flex;
        align-items: center;

        .access {
          color: $gray;
          font-size: 14px;
          font-weight: 500;
          margin-right: 5px;
        }
        .tab {
          background-color: rgba(31, 144, 255, 0.1);
          border-radius: 100px;
          padding: 6px 12px;
          margin-left: 5px;
          color: $menu-blue;
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
    .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;

      .column {
        margin: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .row {
          display: flex;
          align-items: center;
          font-weight: 800;
          font-size: 20px;
          line-height: 34px;

          .icon {
            margin-right: 6px;
          }

          .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 0px;
          }
          .green {
            color: #8ce28b;
          }
          .yellow {
            color: #fede6a;
          }
          .red {
            color: #fd7062;
          }
        }
        .subtitle {
          padding: 0;
          margin: 6px 0 0 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #adaeb6;
        }
      }
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
}
