@import "../variables/theme-variables.scss";

.pageTitleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
  .leftArea {
    display: flex;
    align-items: flex-start;

    .titleText {
      padding: 0;
      margin: 0;

      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
    .subtitleText {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      padding: 0;
      margin: 4px 0 0 0;
      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }
  }
  .rightArea {
    .create {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      img {
        height: 32px;
        width: 32px;
      }
      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
        @include themify($themes) {
          color: themed("primaryBlue");
        }
      }
      .newWorkflowIconContainer {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        @include themify($themes) {
          background-color: themed("primaryBlue");
        }
      }
    }
  }
}
