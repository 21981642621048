@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/modal';

.old-theme {
  /*101*/
  @import 'bootstrap/scss/bootstrap.scss';

  @import '../assets/fonts/simple-line-icons.css';

  @import 'theme/variables';
  /*102*/
  @import 'theme/mixins';

  // Plugins CSS
  /*103*/
  @import 'theme/plugins/plugin-animate-bootstrap-notify';
  /*104*/
  @import 'theme/plugins/plugin-perfect-scrollbar';

  // Core CSS
  /*105*/
  @import 'theme/misc';

  // components
  /*106*/
  @import 'theme/navbar';
  /*107*/
  @import 'theme/dropdown';

  /*108*/
  @import 'theme/images';

  /*109*/
  @import 'theme/sidebar-and-main-panel';
  /*110*/
  @import 'theme/footers';
  /*111*/
  @import 'theme/pagesections';

  /*112*/
  @import 'theme/responsive';

  // Zest Admin
  /*1*/
  @import 'theme/_general';
  /*2*/
  @import 'theme/_typography';
  /*3*/
  @import 'theme/_sidebar';
  /*4*/
  @import 'theme/_progress';
  /*5*/
  @import 'theme/_tooltips';
  /*6*/
  @import 'theme/_popovers';
  /*7*/
  @import 'theme/_calendar';
  /*8*/
  @import 'theme/_timeline';
  /*9*/
  @import 'theme/_pricingtables';
  /*10*/
  @import 'theme/_vectormap';
  /*11*/
  @import 'theme/_dropzone';
  /*12*/
  @import 'theme/_icons';
  /*13*/
  @import 'theme/_members';
  /*14*/
  @import 'theme/_code';
  /*15*/
  @import 'theme/_breadcrumbs';
  /*16*/
  @import 'theme/_pagination';
  /*17*/
  @import 'theme/_badges';
  /*18*/
  @import 'theme/_uigrid';
  /*19*/
  @import 'theme/_cards';
  /*20*/
  @import 'theme/_sections';
  /*21*/
  @import 'theme/_buttons';
  /*22*/
  @import 'theme/_modals';
  /*23*/
  @import 'theme/_listgroup';
  /*24*/
  @import 'theme/_alertnotifications';
  /*25*/
  @import 'theme/_login';
  /*26*/
  @import 'theme/_tabs';
  /*27*/
  @import 'theme/_formelements';
  /*28*/
  @import 'theme/_formcomponents';
  /*29*/
  @import 'theme/_formeditors';
  /*30*/
  @import 'theme/_mailbox';
  /*31*/
  @import 'theme/_widgets';
  /*32*/
  @import 'theme/_autosuggest';
  /*33*/
  @import 'theme/_blogs';
  /*34*/
  @import 'theme/_gallery';
  /*35*/
  @import 'theme/_invoice';
  /*36*/
  @import 'theme/_errorpages';
  /*37*/
  @import 'theme/_lockscreen';
  /*38*/
  @import 'theme/_carousel';
  /*39*/
  @import 'theme/_dashboard';
  /*40*/
  @import 'theme/_notification-widget';
  /*41*/
  @import 'theme/_navigationbar';
  /*42*/
  @import 'theme/_searchpage';
  /*43*/
  @import 'theme/_profilepage';
  /*44*/
  @import 'theme/_tables';
  /*45*/
  @import 'theme/_jumbotron';
}
