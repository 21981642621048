.forgot-password {
  height: 100%;
  width: 100%;
  display: flex;

  .left-container {
    @include login-left-container();
  }
  .right-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3e3d40;

    .form-container {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 32px;
        font-weight: 800;
        color: $white;
        margin: 10px 0;
      }
      .subtitle {
        font-size: 16px;
        font-weight: 600;
        color: $gray;
        margin: 0 0 32px 0;
      }

      .inputArea {
        position: relative;
        margin-bottom: 14px;

        .inputIcon {
          position: absolute;
          top: 25px;
          left: 14px;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
        }

        input {
          width: 100%;
          border: none;
          outline: none;
          border-radius: 8px;
          padding: 10px 10px 10px 42px;
          box-sizing: border-box;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          height: 50px;
          background-color: #575759;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #fff;

          &::placeholder {
            color: #c8cad2;
          }
        }
      }
      .forgot-password-container {
        display: flex;
        align-items: center;

        label {
          color: $light-gray;
          margin-left: 8px;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
        span {
          margin-left: auto;
          color: $blue;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      button {
        @include login-btn();
      }
      .signup-link {
        color: $gray;
        font-size: 16px;
        font-weight: 600;
        margin-top: 32px;
        text-align: center;

        span {
          color: $blue;
          cursor: pointer;
        }
      }
      .copyright-msg {
        color: $gray;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-top: auto;
        margin-bottom: 20px;
      }
    }

    img[alt="champselect-logo"] {
      height: 50px;
      width: 50px;
      margin-top: auto;
    }
  }
}
