.create-workflow-container {
  height: 100%;
  width: 100%;
  display: flex;
  .sidebar-container {
    width: 300px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $silver-rgba-30;

    .header {
      color: $dark;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 55px;
      margin: 60px 40px 0px 40px;
    }
    .step-row {
      color: $quick-silver;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin: 30px 40px 0px 40px;
      cursor: pointer;

      &:first-child {
        margin-top: 55px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        background: $gray-rgba-20;
        margin-right: 10px;
      }
    }
    .step-row-active {
      color: $blue;
      div {
        color: $blue;
        background: $blue-rgba-20;
      }
    }
    .leave {
      height: 140px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 40px;
      margin: auto 0 0 0;
      border-top: 1px solid $silver-rgba-30;

      img {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }
    }
  }
  .steps-container {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #fafafb;
    padding: 20px 40px 12px 40px;
    box-sizing: border-box;
    overflow: auto;

    .header {
      min-height: 11%;
      width: 100%;
      display: flex;
      align-items: center;

      .title {
        color: $dark;
        font-size: 32px;
        font-weight: 800;
        padding-right: 20px;
        border-right: 1px solid $light-gray;
      }
      .sub-title {
        color: $gray;
        font-size: 16px;
        font-weight: 600;
        margin-left: 20px;
        margin-right: auto;
      }
      .btn-next {
        background: $blue;
        padding: 11px 24px;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        border: none;
        cursor: pointer;

        img {
          height: 18px;
          width: 18px;
        }
        span {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 800;
          line-height: 18px;
          vertical-align: top;
        }
      }
      .btn-back {
        background: $gray-action;
        color: $dark;
        margin-right: 11px;
      }
      .btn-inactive {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
