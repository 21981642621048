img {
  max-width: 100%;
  border-radius: $border-radius-small;
}
.img-raised {
  box-shadow: $box-shadow-raised;
}
.avatar-image {
  border-radius: 5px;
}
