@import "../variables/theme-variables.scss";

.navbar {
  max-height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  @include themify($themes) {
    background: themed("navbarBackgroundColor");
  }

  .logoArea {
    display: flex;
    align-items: center;
    .logoContainer {
      border-radius: 16px;
      height: 70px;
      display: flex;
      align-items: center;

      .logo {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        transition: 0.2s all linear;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
    }
    .verticalSeparator {
      width: 3px;
      height: 20px;
      border-radius: 4px;
      margin: 0 10px;
      @include themify($themes) {
        background-color: themed("primaryTextColor");
      }
    }
    .org {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      opacity: 0.5;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
  }
  .menuContainer {
    height: 70px;
    display: flex;
    justify-content: center;

    .orders,
    .programs,
    .discounts,
    .forms,
    .reservations,
    .rosters,
    .allApps,
    .my-applications,
    .workflows,
    .profile,
    .teams,
    .clients,
    .billing {
      display: flex;
      align-items: center;
      transition: 0.2s all linear;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 0 20px;
      opacity: 0.5;
      @include themify($themes) {
        border-bottom: themed("navbarInActiveBorder");
        color: themed("primaryTextColor");
      }
      &:hover {
        opacity: 1;
      }
    }
    .activeTab {
      opacity: 1;
      @include themify($themes) {
        color: themed("primaryBlue");
        border-bottom: themed("navbarBorder");
      }
    }
  }

  .settingsArea {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;

    .clickableArea {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .imageContainer {
        width: 32px;
        height: 32px;
        border-radius: 50%;

        .image {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }
      .dropdown {
        margin-left: 10px;
      }

      .dropdownContainer {
        position: absolute;
        width: 190px;
        right: 20px;
        top: 64px;
        z-index: 200;
        border-radius: 8px;

        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px 15px 10px 10px;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        @include themify($themes) {
          color: themed("primaryTextColor");
          background: themed("navbarBackgroundColor");
        }

        .menuItem {
          display: flex;
          align-items: center;
          cursor: pointer;

          border-radius: 8px;
          width: 100%;
          height: 50px;
          position: relative;

          .menuText {
            padding-left: 60px;
            position: relative;
          }
          .mode {
            margin-left: 14px;
          }

          .darkmode-switch {
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 0;

            .switch-container {
              @include darkmode-switch();
              margin-left: 0;
              width: 52px;
            }
          }
          .icon {
            position: absolute;
            z-index: 0;
            margin-left: 10px;
            width: 24px;
            height: 24px;
          }

          &:hover {
            @include themify($themes) {
              color: themed("primaryTextColor");
              background: themed("backgroundShade");
            }
          }

          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
  }
}
