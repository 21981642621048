.campminderLoginPage {
  height: 100vh;
  font-family: "Lucida Grande";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #032737;
    padding: 50px;
    box-sizing: border-box;

    .mainArea {
      display: flex;
      align-items: center;
      justify-content: center;

      .logoArea {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          height: 60px;
        }
      }

      .verticalLine {
        border-radius: 4px;
        background-color: #fff;
        width: 5px;
        height: 350px;
        margin: 10px 40px;
      }

      .columnContainer {
        display: flex;
        flex-direction: column;
        width: 300px;

        .adminIcon {
          height: 24px;
          width: 100%;
          margin: 20px 0;
        }

        .inputArea {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;

          label {
            color: #fff;
            margin-bottom: 6px;
            font-family: "Lucida Grande";
            font-size: 13px;
          }

          input {
            outline: none;
            border: none;
            border-radius: 4px;
            padding: 14px;
            &:focus {
              -webkit-box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75);
              -moz-box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75);
              box-shadow: 0px 0px 5px rgba(56, 169, 240, 0.75);
            }
          }
        }

        .submit {
          outline: none;
          border: none;
          cursor: pointer;
          background-color: #79bc99;
          padding: 14px;
          border-radius: 4px;
          transition: 0.2s all ease-in-out;
          margin-top: 10px;
          font-weight: 600;

          &:hover {
            background-color: #a7daa6;
          }
        }

        .errorMessage {
          color: #fff;
          font-family: "Lucida Grande";
          font-size: 13px;
          text-align: center;
          margin: 20px 20px;
        }
      }
    }
  }
}
