/*-----------------------------------------------------------
    UI Lockscreen
----------------------------------------------------------*/

.lockscreen_info img {
  margin-top: 100px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 120px;
  height: 120px;
  margin-right: 0px;
}
.lockscreen_info {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 21px;
  line-height: 32px;
  color: #999999;
  font-weight: 400;
  margin: 15px 0;
}
.lockscreen_search {
  margin: 15px 0;
}
.lockscreen_search input[type="password"] {
  border: 1px solid #f5f5f5;
  background-color: #fcfcfc;
}
.lockscreen_search input[type="submit"] {
  visibility: hidden;
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px;
  margin: 0px;
}
.lockscreen_btn {
  margin: 30px 0 0 0;
}
.lockscreen_search i {
  font-size: 21px;
}
.lockscreen_tagline {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  color: #aaaaaa;
  font-weight: 300;
  margin: 0px 0 15px 0;
}
