.mailchimpSettingsSection {
  background: #575759;
  border-radius: 8px;
  height: 300px;
  transition: 0.2s ease-in-out;
  overflow-y: hidden;
  margin-bottom: 40px;

  .settingsTitleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    .textArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .titleText {
        margin: 0 0 6px 0;
        padding: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      }
      .desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #adaeb6;
        padding: 0;
        margin: 0;
        width: 85%;
      }
    }

    .accordionArea {
      cursor: pointer;

      .accordionIcon {
        transition: 0.2s ease-in-out;
      }

      .closeAccordion {
        transform: rotate(180deg);
      }
    }
  }
  .settingsBodyArea {
    padding: 0 30px 30px 30px;
    .row {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 25px;
      width: 100%;

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 30px;

        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 6px;
          span {
            opacity: 0.5;
          }
        }
        .input {
          background: #4d4d51;
          border-radius: 8px;
          border: none;
          outline: none;
          padding: 20px;
          width: 260px;
          color: #fff;

          &::placeholder {
            color: #adaeb6;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: #adaeb6;
          }

          &::-ms-input-placeholder {
            color: #adaeb6;
          }
        }
      }
      .submitButton {
        padding: 17px 20px;
        background: #1f90ff;
        border-radius: 8px;
        outline: none;
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #fff;
        cursor: pointer;
      }
      .addButton,
      .removeButton {
        outline: none;
        border: none;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .addButton {
        color: #81b9f9;
        margin-right: 20px;
      }
      .removeButton {
        color: #fd7062;
      }
    }
    .helpText {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #81b9f9;
      cursor: pointer;
    }
  }
}
.closeSettingsAccordion {
  height: 130px;
  overflow-y: hidden;
}
