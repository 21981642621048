/*---------------------------------------------
    Form Editors
----------------------------------------------*/
/*bootstrap wysihtml5*/
.rdw-editor-main {
  min-height: 200px;
  border: 1px solid #eee;
  margin-top: -6px;
  padding: 0 15px;
}
