.billingPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  @include themify($themes) {
    color: themed("primaryTextColor");
  }

  .billingHistoryContainer {
    margin: 24px 0;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;

    @include themify($themes) {
      color: themed("primaryTextColor");
      background-color: themed("secondaryBackgroundColor");
    }

    .filterByDateArea {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .filterInputArea {
        width: 320px;
        height: 60px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        margin-bottom: 24px;

        @include themify($themes) {
          background: themed("primaryInputFieldBackground");
          color: themed("secondaryTextColor");
        }

        .icon {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
        }
      }

      .filteredDataArea {
        display: flex;
        flex-direction: column;

        .dateText {
          margin-bottom: 6px;
          @include themify($themes) {
            color: themed("primaryTextColor");
          }
          span {
            @include themify($themes) {
              color: themed("secondaryTextColor");
            }
          }
        }
      }
    }
    .dateFilterArea {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 90px;
      left: 20px;
      z-index: 11;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

      @include themify($themes) {
        background: themed("primaryInputFieldBackground");
      }
    }

    .tableContainer {
      border-radius: 8px;
      width: 100%;
      margin-top: 20px;
      overflow: hidden;
      position: relative;

      @include themify($themes) {
        background: themed("primaryBackgroundColor");
        border: themed("borderPrimary");
      }

      .tableHeading {
        width: 100%;
        display: flex;
        align-items: center;
        @include themify($themes) {
          background: themed("primaryInputFieldBackground");
        }

        .head {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          padding: 14px;
          @include themify($themes) {
            color: themed("primaryTextColor");
          }
        }
        .one {
          width: 25%;
        }
        .two,
        .three,
        .four {
          width: 20%;

          display: flex;
          align-items: center;
        }

        .emptyTransaction {
          position: absolute;
          width: 100%;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #adaeb6;
            margin: 24px 0;
          }
        }
      }
      .tableBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        .tableRow {
          width: 100%;
          display: flex;
          align-items: center;

          @include themify($themes) {
            color: themed("primaryTextColor");
            background: themed("secondaryBackgroundColor");
            border-top: themed("borderPrimary");
          }
          &:hover {
            @include themify($themes) {
              background: themed("primaryBackgroundColor");
            }
          }

          .row {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            padding: 14px;
            display: flex;
            align-items: center;
            @include themify($themes) {
              color: themed("primaryTextColor");
            }
          }
          .one {
            width: 25%;
          }
          .two,
          .three,
          .four {
            width: 20%;
          }
        }
        .emptyContainer {
          margin-top: 20px;
          @include themify($themes) {
            background: themed("secondaryBackgroundColor");
          }
          .emptyText {
            text-align: center;
            color: #adaeb6;
            margin: 50px 0;
          }
        }
      }
      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        .spinner {
          &::before {
            @include themify($themes) {
              background: themed("secondaryBackgroundColor");
            }
          }
        }
      }
    }
    .loadingTable {
      opacity: 0.7;
    }
    .filterAndPaginationContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 0 0;
      color: #adaeb6;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      .filter {
        display: flex;
        align-items: center;

        .select {
          margin-left: 10px;
          display: grid;
          grid-template-areas: "select";
          align-items: center;
          position: relative;

          select,
          &::after {
            grid-area: select;
          }

          &::after {
            content: "";
            justify-self: end;
            width: 0.8rem;
            height: 0.5rem;
            background-color: #adaeb6;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            margin-right: 12px;
          }

          select {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            border-radius: 8px;
            padding: 12px 28px 12px 12px;
            outline: none;
            appearance: none;
            cursor: pointer;
            color: #adaeb6;
            border: none;
            @include themify($themes) {
              background: themed("primaryInputFieldBackground");
            }
          }
        }
      }
      .pagination {
        display: flex;
        .leftArrow {
          cursor: pointer;
          margin-right: 30px;
        }
        .previousPageNumber,
        .nextPageNumber,
        .pageNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 16px;
          border-radius: 8px;
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
          margin-right: 20px;
          color: #ffffff;

          @include themify($themes) {
            background: themed("primaryBlue");
          }
        }
        .previousPageNumber,
        .nextPageNumber {
          cursor: pointer;
          color: #adaeb6;

          @include themify($themes) {
            background: themed("primaryInputFieldBackground");
          }
        }
        .pageNumber {
          color: #ffffff;

          @include themify($themes) {
            background: themed("primaryBlue");
          }
        }
        .rightArrow {
          cursor: pointer;
        }
      }
    }
  }
  .content {
    display: flex;
    margin: 30px 0 20px 0;

    .left {
      margin: 0 30px 30px 0;
      display: flex;
      flex-direction: column;
      width: 55%;
      .monthlyTotalContainer {
        padding: 30px;
        background-color: #2d2d31;
        border-radius: 18px;
        margin-bottom: 30px;
        .titleArea {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .title {
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 31px;
            color: $white;
            margin-bottom: 12px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #adaeb6;
          }
          .subtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $white;
            margin-top: 6px;
          }
        }
        .priceArea {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          font-weight: 800;
          font-size: 48px;
          line-height: 61px;
          color: #1f90ff;

          span {
            font-weight: 700;
            font-size: 24px;
            line-height: 51px;
            margin-left: 10px;
          }
        }
      }
      .billingBreakdownContainer {
        background-color: #2d2d31;
        border-radius: 18px;
        margin-bottom: 30px;
        .titleArea {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;
          padding: 30px 30px 0 30px;

          .title {
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 31px;
            color: $white;
            margin-bottom: 12px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #adaeb6;
          }
        }
        .individual {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          padding: 0 30px 20px 30px;
          .details {
            color: $white;
          }
          .individualPrice {
            color: #81b9f9;

            span {
              margin-right: 10px;
              font-weight: 900;
            }
          }
        }
        .appliedBalance {
          .details {
            color: #ffffffae;
          }
        }
        .horizontalLine {
          height: 1px;
          width: 100%;
          background-color: #afb5bb51;
          margin: 16px 0;
        }
        .totalContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          padding: 10px 30px 30px 30px;

          .total {
            color: $white;
          }

          .totalPrice {
            color: #81b9f9;
          }
        }
      }
      .wrapper {
        height: 550px;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #2d2d31;
        border-radius: 18px;
        .transactionHistory {
          background-color: #2d2d31;
          border-radius: 18px;
          flex: 1;
          height: inherit;
          overflow-y: auto;
          margin-right: 10px;
          padding-right: 10px;
          box-sizing: border-box;

          .mainAreaTitle {
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 13px;
            padding: 30px 30px 0 30px;
          }
          .mainAreaSubtitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            padding: 0 30px 0 30px;
            color: #adaeb6;
            margin-bottom: 22px;
          }
          .scrollableContainer {
            overflow-x: auto;
            height: 400px;
            flex: 1;
            padding-right: 10px;
            margin-bottom: 30px;

            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: #adaeb6;
            }
            &::-webkit-scrollbar-thumb {
              background: #575759;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #575759;
              transform: scaleX(0.9);
            }
            table {
              border-collapse: collapse;
              width: 100%;
              font-style: normal;
              position: relative;

              thead {
                position: sticky;
                top: 0;
                height: 60px;
                background-color: #2d2d31;
                z-index: 6;
                tr {
                  th {
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 20px;
                    color: $white;

                    &:nth-child(1) {
                      padding-left: 30px;
                    }

                    .status {
                      margin: 0 10px;
                    }
                    .tableHead {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      margin-bottom: 18px;
                    }
                  }
                }
              }

              tbody {
                position: relative;
                tr {
                  height: 80px;
                  border-radius: 16px;
                  border-top: 1px solid #afb5bb42;

                  td {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $white;

                    &:nth-child(1) {
                      padding-left: 30px;
                    }

                    .status {
                      margin: 0 10px;
                      .statusCircle {
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                        border-radius: 50%;
                      }
                    }
                  }

                  &:hover {
                    background: #38373a;
                  }
                }
                .emptyTransaction {
                  position: absolute;
                  width: 100%;
                  height: 300px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #adaeb6;
                    margin: 24px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .right {
      width: 45%;
      .paymentDetails {
        padding: 30px;
        background-color: #2d2d31;
        border-radius: 18px;
        margin-bottom: 30px;
        .titleArea {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .title {
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 31px;
            color: $white;
            margin-bottom: 12px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $light-gray;
          }
        }
        .cc-row {
          margin-top: 8px;
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          height: 60px;
          width: calc(100%);
          background: #575759;
          border-radius: 8px;

          .leftRow {
            display: flex;
            align-items: center;
            img {
              margin-left: 20px;
              height: 26px;
            }
            .name {
              margin-left: 20px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
            }
          }

          .number {
            margin-left: auto;
            font-weight: 700;
            margin-right: 20px;
          }
        }
        .addPaymentMethod {
          width: 100%;
          height: 72px;
          background: rgba(31, 144, 255, 0.1);
          border: 1px dashed #1f90ff;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #1f90ff;
          cursor: pointer;

          .icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.showUpdatePaymentInformationModal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
}
.updatePaymentContainer {
  position: fixed;
  width: 450px;
  overflow: hidden;
  height: 550px;
  background-color: #3e3d40;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  @keyframes expand {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .titleArea {
    margin-bottom: 20px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      color: $white;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      color: #adaeb6;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .inputGroup {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
      }

      input {
        background: #575759;
        font-weight: 600;
        font-size: 14px;
        outline: none;
        border: none;
        margin: 8px 8px 8px 0;
        box-sizing: border-box;
        border-radius: 8px;
        height: 50px;
        padding: 12px;
        line-height: 18px;
        width: 100%;

        color: $white;
        &::placeholder {
          color: #adaeb6;
          opacity: 1;
          font-weight: 500;
        }

        :-ms-input-placeholder {
          color: #adaeb6;
        }

        ::-ms-input-placeholder {
          color: #adaeb6;
        }
      }
      .inputFieldIcon {
        position: absolute;
        height: 16px;
        bottom: 26px;
        left: 12px;
      }
    }
  }
  .grayText {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #adaeb6;
    text-align: center;
    width: 100%;
  }
  .errorText {
    color: #fd7062;
    margin-bottom: 20px;
    margin-top: -10px;
    flex: 1;
    height: 30px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 8px;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }
  .submit {
    background: #1f90ff;
    color: #fff;
    margin-bottom: 20px;
  }
  .cancel {
    background-color: #e3e3e6;
    color: #424451;
  }
}
.cancelSubscriptionModal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
}
.cancelSubscriptionContainer {
  position: fixed;
  width: 440px;
  background-color: #fff;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;

  @keyframes expand {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .title {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: #424451;
    margin-bottom: 18px;
  }
  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #424451;
    margin-bottom: 18px;
  }
  .actionArea {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-top: 18px;
      border-radius: 8px;
      padding: 20px;
      outline: none;
      border: none;
      color: #fff;
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
    }

    .submitButton {
      background: #1f90ff;
      color: #fff;
      width: 50%;
    }
    .cancelButton {
      background: #e3e3e6;
      color: #424451;
      margin-right: 14px;
      width: 50%;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10;
  background-color: #000;
  opacity: 0.3;
}
