.content {
  .row,
  form {
    .form-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0 0 0;

      .input-group {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .input {
          width: 360px;
          input {
            width: 360px;
            margin-bottom: 4px;
            background: #4d4d51;
            border: none;
            box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
            height: 50px;
            padding: 20px 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            outline: none;
            color: $white;

            &:disabled {
              color: #adaeb6;
              cursor: not-allowed;
            }

            &::placeholder {
              color: #adaeb6;
              font-weight: 400;
              letter-spacing: 0.5px;
            }

            :-ms-input-placeholder {
              color: #adaeb6;
              letter-spacing: 0.5px;
            }

            ::-ms-input-placeholder {
              color: #adaeb6;
              letter-spacing: 0.5px;
            }
          }
        }
        .lockIcon {
          position: absolute;
          left: 10px;
          top: 28px;
          transform: translateY(-50%);
        }

        .showButton {
          position: absolute;
          right: 15px;
          top: 28px;
          transform: translateY(-50%);
          z-index: 100;
          cursor: pointer;
        }
        .lowerTop {
          top: 28px;
          transform: translateY(-50%);
        }
        .gray-span {
          color: $gray !important;
          pointer-events: none;
        }
      }

      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start !important;

        label {
          margin-bottom: 1rem;
        }

        .input-group {
          width: 100% !important;
        }
      }

      .new-password {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .newPassword {
          width: 200px;
          color: $white;
          text-align: left;
          margin-right: 20px;
        }
      }

      .red-border {
        border: 1px solid #fd7062 !important;
      }

      .wrong-password {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #fd7062;
      }

      .oldPassword {
        width: 200px;
        color: $white;
        text-align: left;
        margin-right: 20px;
      }
      .oldPassword {
        padding-bottom: 16px;
      }
      .newPassword {
        padding-top: 16px;
      }
      .newConfirmPassword {
        padding-bottom: 16px;
      }

      .input-group {
        width: 100%;

        img {
          top: 20%;
          left: 8px;
          z-index: 50;
        }

        input {
          background-color: #fff;
          border: 1px solid #c8cad2;
          border-radius: 8px !important;
          outline: none;
          padding: 12px 60px 12px 32px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        span {
          top: 22%;
          right: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #81b9f9;
          cursor: pointer;
          user-select: none;
        }

        .gray-span {
          color: $gray !important;
          pointer-events: none;
        }
      }

      .password-advice {
        background: #3e3d40;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        align-self: flex-end;

        @media screen and (max-width: 540px) {
          width: 100%;
        }

        small {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: $white;
        }

        .green-advice {
          color: #8ce28b;
          margin: 6px;
        }

        .red-advice {
          color: #fd7062;
          margin: 6px;
        }
      }
    }
    .newConfirmPassword {
      width: 200px;
      color: $white;
      text-align: left;
      margin-right: 20px;
    }
    .noBorder {
      border-bottom: none;
    }

    .password-buttons {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        border-radius: 8px;
        padding: 12px 24px;
        outline: none;
        border: none;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
      button:first-child {
        background-color: $platinum;
        margin-right: 1rem;
      }
      button:last-child {
        background-color: $blue;
        color: $white;
      }
    }
  }
}
