/*----------------------------------------
  Sidebar Main Menu
------------------------------------------*/

.sidebar .menustats {
  padding: 15px 15px 15px 20px;
}

.sidebar .menustats h5 {
  font-size: 13px;
}

.sidebar .menustats .progress {
  height: 5px;
  background-color: #eeeeee;
  margin-bottom: 25px;
}
