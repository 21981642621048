.checkboxMC {
  border-color: gray !important;
}

.tooltipSpan {
  background-color: gray;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  display: inline-block;
}
