.max-width {
  width: 100%;
}

.MuiGrid-item {
  align-self: flex-end;
}

.button-ui {
  color: #ffffff;
}
