$white: #fff;
$black: #000;
$dark: #424451;
$gray: #adaeb6;
$gray-rgba-20: rgba(173, 174, 182, 0.2);
$light-gray: #c8cad2;
$surface-gray: #fafafe;
$gray-action: #e3e3e6;
$anti-flash-white: #f2f2f2;
$menu-blue: #81b9f9;
$blue: #1f90ff;
$blue-rgba-20: rgba(31, 144, 255, 0.2);
$ghost-white: #fafcfe;
$silver: #afb5bb;
$silver-rgba-30: rgba(175, 181, 187, 0.3);
$quick-silver: #a1a2aa;
$platinum: #e3e3e6;
$light-green-rgba-30: rgba(140, 226, 139, 0.3);
