.myClientsPage {
  font-family: "Poppins";
  color: #ffffff;
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  .filterArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .inputArea {
      width: 300px;
      position: relative;

      .filterByName {
        width: 100%;
        background: #4d4d51;
        border-radius: 4px;
        outline: none;
        border: none;
        padding: 10px 10px 10px 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #adaeb6;
        font-family: "Poppins";
        font-style: normal;
      }
      .searchIcon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .filterButtonArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .filterButton {
        outline: none;
        border: none;
        cursor: pointer;
        background: transparent;
        color: #81b9f9;
        font-style: normal;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 8px;
          height: 20px;
          width: 20px;
        }
      }
      .filterCancelButton {
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        background: transparent;
        color: #fd7062;
        font-style: normal;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        border-radius: 4px;
        margin-left: 20px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }
  .bodyContainer {
    width: 100%;
    margin-bottom: 20px;

    table {
      border-collapse: collapse;
      width: 100%;
      font-style: normal;
      position: relative;

      thead {
        position: sticky;
        top: -50px;
        height: 50px;
        z-index: 6;
        background-color: #3e3d40;
        tr {
          th {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #adaeb6;
            font-family: "Poppins";
            font-style: normal;

            &:nth-child(1) {
              padding-left: 30px;
            }
            .tableHead {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 18px;
            }
          }
        }
      }

      tbody {
        position: relative;
        tr {
          height: 60px;
          border-radius: 16px;
          border-top: 1px solid #afb5bb42;
          cursor: pointer;
          z-index: 1;

          td {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
            font-family: "Poppins";
            font-style: normal;

            &:nth-child(1) {
              padding-left: 30px;
            }

            .editableArea {
              display: flex;
              align-items: center;
              position: relative;
              width: 250px;
              position: relative;

              .editUsername {
                font-family: "Poppins";
                font-style: normal;
                outline: none;
                border: none;
                background: #4d4d51;
                border-radius: 4px;
                padding: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #adaeb6;
                width: 200px;
                animation: expandfromzero 0.3s ease-in-out;
                &::placeholder {
                  color: #adaeb6;
                }
                &:-ms-input-placeholder {
                  color: #adaeb6;
                }
                &::-ms-input-placeholder {
                  color: #adaeb6;
                }

                @keyframes expandfromzero {
                  0% {
                    width: 0;
                  }
                  100% {
                    width: 200px;
                  }
                }
              }
              .transparentButton {
                outline: none;
                border: none;
                cursor: pointer;
                background: transparent;
                font-weight: 600;
                font-size: 14px;
                font-family: "Poppins";
                font-style: normal;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #81b9f9;
                margin-left: 20px;
                transition-delay: 0.3s;
              }

              .icon {
                margin-right: 8px;
                cursor: pointer;
              }
              .text {
                cursor: pointer;
              }

              .tooltip {
                position: absolute;
                width: 100px;
                height: 36px;
                background-color: #000000be;
                border-radius: 8px;
                top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                  position: absolute;
                  border-right: 6px solid transparent;
                  border-bottom: 6px solid #000000be;
                  border-left: 6px solid transparent;
                  content: "";
                  height: 0;
                  width: 0;
                  top: -5px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
            .appsContainer {
              display: flex;
              align-items: center;
              .imageContainer {
                width: 36px;
                height: 36px;
                background: #2d2d31;
                border-radius: 4px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                  width: 20px;
                  height: 20px;
                }
                .moreApp {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  font-family: "Poppins";
                  font-style: normal;
                  line-height: 21px;
                  color: #81b9f9;
                }
              }
            }
            .errorContainer {
              background: #fd7062;
              border-radius: 100px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &:hover {
            background: #38373a;
          }
        }
        .unselectedRow {
          opacity: 0.3;
        }
        .emptyTransaction {
          position: absolute;
          width: 100%;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          span {
            font-weight: 600;
            font-size: 16px;
            font-family: "Poppins";
            font-style: normal;
            line-height: 20px;
            color: #adaeb6;
            margin: 24px 0;
          }
        }
      }
    }
  }
}

.filterCategoriesSidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  z-index: 100;
  padding: 60px 30px;
  background-color: #575759;
  animation: expand 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  @keyframes expand {
    from {
      right: -300px;
    }
    to {
      right: 0;
    }
  }

  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    .filterTitleText {
      font-weight: 700;
      font-size: 24px;
      font-family: "Poppins";
      font-style: normal;
      line-height: 36px;
      color: #ffffff;
      padding: 0;
      margin: 0;
    }

    .cancelArea {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .buttonsArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    .button {
      background: #1f90ff;
      border-radius: 4px;
      outline: none;
      border: none;
      padding: 12px 18px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      min-width: 136px;
      cursor: pointer;
    }
    .primary {
      background: #1f90ff;
      color: #ffffff;
    }
    .secondary {
      background: #adaeb6;
      color: #000000;
    }
  }
  .appsArea {
    overflow-y: auto;
    margin-bottom: 90px;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #adaeb6;
    }
    &::-webkit-scrollbar-thumb {
      background: #3e3d40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3e3d40;
      transform: scaleX(0.9);
    }
    .appArea {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #adaeb6ac;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }

      .rowLeft {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .iconArea {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3e3d40;
          border-radius: 4.8px;
          margin-right: 16px;

          .icon {
            width: 22px;
            height: 22px;
          }
        }

        .appName {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }

      .checkboxArea {
        position: relative;

        .checkmark {
          position: relative;
          cursor: pointer;
          padding: 0;

          &::before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: #575759;
            border-radius: 4px;
            border: 1px solid #adaeb6ac;
            transition: 0.2s all ease-in-out;
          }

          &::after {
            content: "";
            position: absolute;
          }
        }
        .checkbox {
          cursor: pointer;
          opacity: 0.5;
          display: none;

          &:checked ~ .checkmark::before {
            background: #1f90ff;
            border: 1px solid #575759;
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            transition: 0.2s all ease-in-out;
          }

          &:checked ~ .checkmark::after {
            left: 6px;
            top: 10px;
            background: #ffffff;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
              4px -8px 0 white;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.appDetailsModal {
  width: 750px;
  max-height: 700px;
  position: fixed;
  overflow: hidden;
  background-color: #575759;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  @keyframes expand {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .header {
    width: 100%;
    border-bottom: 1px solid #adaeb6ac;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .titleText {
      padding: 0;
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
    }

    .iconArea {
      cursor: pointer;
    }
  }
  .scrollableContainer {
    padding: 20px 20px 20px 30px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .generalInformation {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        margin-right: 20px;
      }
      .textArea {
        display: flex;
        flex-direction: column;

        .clientName {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin: 0 0 6px 0;
          padding: 0;
          font-family: "Poppins";
          font-style: normal;
        }
        .clientUsername {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #adaeb6;
          margin: 0;
          padding: 0;
          font-family: "Poppins";
          font-style: normal;
        }
      }
    }

    .allAppsArea {
      overflow-y: auto;
      max-height: 400px;
      min-height: 100px;
      padding-right: 20px;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #adaeb6;
      }
      &::-webkit-scrollbar-thumb {
        background: #3e3d40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #3e3d40;
        transform: scaleX(0.9);
      }

      table {
        border-collapse: collapse;
        width: 100%;
        font-style: normal;
        position: relative;

        thead {
          position: sticky;
          top: 0;
          height: 40px;
          z-index: 6;
          background-color: #575759;
          tr {
            th {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #adaeb6;
              font-family: "Poppins";
              font-style: normal;

              &:nth-child(1) {
                padding-left: 30px;
              }
              .tableHead {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 18px;
              }
            }
          }
        }

        tbody {
          position: relative;
          tr {
            height: 60px;
            border-radius: 16px;
            border-top: 1px solid #afb5bb42;
            cursor: pointer;

            td {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              font-family: "Poppins";
              font-style: normal;

              &:nth-child(1) {
                padding-left: 30px;
              }
              .appDetail {
                display: flex;
                align-items: center;
                .iconArea {
                  width: 36px;
                  height: 36px;
                  background: #2d2d31;
                  border-radius: 4px;
                  margin-right: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    width: 20px;
                    height: 20px;
                  }
                }
                .appName {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #ffffff;
                  font-family: "Poppins";
                  font-style: normal;
                }
              }
              .statusContainer {
                display: flex;
                align-content: center;
                .icon {
                  margin-right: 10px;
                }
              }
            }

            &:hover {
              background: #38373a;
            }
          }
          .unselectedRow {
            opacity: 0.3;
          }
          .emptyTransaction {
            position: absolute;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: #adaeb6;
              margin: 24px 0;
              font-family: "Poppins";
              font-style: normal;
            }
          }
        }
      }
      .appArea {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #adaeb6ac;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }

        .iconArea {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #3e3d40;
          border-radius: 4.8px;

          .icon {
            width: 38px;
            height: 38px;
          }
        }
        .disconnectButton {
          outline: none;
          border: none;
          cursor: pointer;
          background-color: transparent;
          color: #fd7062;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.emptyContainer {
  text-align: center;
  padding: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
}
