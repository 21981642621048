/*-----------------------------------------------------------
    Carousel
----------------------------------------------------------*/
.carousel-item .carousel-bg-img {
  width: 100%;
}

.carousel-indicators .active {
  width: 15px;
  height: 15px;
  margin: 1px;
  background-color: #f5f5f5;
}
.carousel-indicators {
  bottom: 15px;
  margin-bottom: 0px;
}
.carousel-indicators li {
  width: 13px;
  height: 13px;
  margin: 2px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #f5f5f5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.carousel-control.left,
.carousel-control.right {
  background-image: none;
}
.carousel-control {
  width: 15%;
  color: #f5f5f5;
  text-align: center;
  text-shadow: 0;
  filter: alpha(opacity=60);
  opacity: 0.6;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #f5f5f5;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}
.carousel-caption {
  right: 20%;
  left: 20%;
  padding-bottom: 15px;
  background: transparent;
}
.carousel-caption {
  position: absolute;
  right: 0%;
  top: 0px;
  bottom: auto;
  left: 0%;
  width: 100%;
  z-index: 10;
  padding: 30px;
  color: #f5f55;
  text-align: left;
  text-shadow: none;
}
.carousel-caption h3,
.carousel-caption p {
  color: #ffffff;
  text-shadow: none;
  width: auto;
  display: inline-block;
}
.carousel-caption .clearfix {
  width: 100%;
  clear: both;
}
.carousel-caption h3 {
  background: $primary-color;
  padding: 8px 15px;
}
.carousel-caption p {
  background: #777777;
  padding: 8px 15px;
}
.carousel-control-prev,
.carousel-control-next {
  cursor: pointer;
  z-index: 11;
}
