.appSettingsPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  @include themify($themes) {
    color: themed("primaryTextColor");
  }

  .navigationArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    border-bottom: 1px solid #adaeb6ac;
    margin-bottom: 16px;
    .backIconContainer {
      cursor: pointer;
      height: 20px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navigationText {
      margin-right: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
    }
    .primaryButton {
      margin-left: auto;
      border-radius: 4px;
      padding: 12px 24px;
      font-size: 14px;
      font-weight: 600;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      @include themify($themes) {
        background: themed("primaryBlue");
      }

      & + .primaryButton {
        margin-left: 10px;
      }
    }
  }

  .header {
    padding: 6px 30px;
    height: 100px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .whiteBackground {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 8px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          height: 40px;
          width: 40px;
        }
      }
      .textArea {
        color: #ffffff;

        .titleText {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
        }
        .subtitleText {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;

          opacity: 0.7;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      .dropdownContainer {
        position: absolute;
        width: 200px;
        right: 20px;
        top: 30px;
        z-index: 2;
        background: #4d4d51;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
          0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px 20px 10px 10px;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        @include themify($themes) {
          background: themed("backgroundShade");
          color: themed("primaryTextColor");
        }

        .menuItem {
          display: flex;
          align-items: center;
          cursor: pointer;

          border-radius: 8px;
          width: 100%;
          height: 50px;
          padding-left: 10px;
          .icon {
            margin-right: 16px;
          }

          &:hover {
            @include themify($themes) {
              background: themed("navbarBackgroundColor");
              color: themed("primaryTextColor");
            }
          }

          span {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .optionsContainer {
    .optionsTitle {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      margin: 0 0 16px 0;
      padding: 0;
    }
    .options {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .option {
        width: 50%;
        .title {
          margin: 0 0 16px 0;
          padding: 0;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
        }
        .whiteText {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
        .subtitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #adaeb6;
          margin: 0 0 60px 0;
          padding: 0;
        }

        .codeBoxArea {
          position: relative;
          padding: 20px;
          width: 580px;
          min-height: 240px;
          margin: 0 16px 24px 0;

          .backgroundBox {
            position: absolute;
            z-index: 0;
            width: 600px;
            top: 0;
            left: 0;
            margin: -36px 0 30px 0;
          }
          .codeBox {
            border-radius: 8px;
            position: relative;
            z-index: 1;

            pre {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;

              span {
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
              }
              .htmlBuiltin {
                color: #ec5d6c;
              }
              .string {
                color: #f4edab;
                @include themify($themes) {
                  color: themed("primaryYellow");
                }
              }
            }
          }
        }

        .copyToClipboard {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-top: 40px;

          .copyToClipboardIcon {
            margin-right: 10px;
          }

          .copyText {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            @include themify($themes) {
              color: themed("primaryBlue");
            }
          }
        }
      }
      .optionTwo {
        width: 46%;
        padding: 0 0 0 50px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            border: none;
            font-size: 14px;
            font-weight: 600;
            padding: 10px;
            background: inherit;
            margin-left: auto;
            cursor: pointer;
            @include themify($themes) {
              color: themed("primaryBlue");
            }
          }
        }

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          padding: 0;
          margin: 0 0 20px 0;
        }
        .connectAppsCard {
          border: 1px solid rgba(173, 174, 182, 0.5);
          border-radius: 8px;
          max-height: 408px;
          height: 100%;
          padding: 26px 0;
          box-sizing: border-box;

          .cardTitle {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            margin: 0;
            padding: 0 26px;
            @include themify($themes) {
              color: themed("primaryTextColor");
            }
          }
          .cardSubtitle {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #adaeb6;
            padding: 0 26px;
            margin: 0 0 26px 0;
            opacity: 0.5;
          }

          .scrollableContainer {
            margin: 0 6px 0 0;
            overflow-y: auto;
            max-height: 280px;
            height: 100%;
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              @include themify($themes) {
                background-color: themed("scrollbarTrack");
              }
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              @include themify($themes) {
                background-color: themed("scrollbarThumb");
              }
            }
            &::-webkit-scrollbar-thumb:hover {
              transform: scaleX(0.9);
              @include themify($themes) {
                background-color: themed("scrollbarThumb");
              }
            }
            .buttonsContainer {
              position: relative;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              padding: 0 20px 0 26px;
              .button {
                padding: 12px;
                box-sizing: border-box;
                width: 100%;
                border-radius: 8px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                @include themify($themes) {
                  background: themed("backgroundShadeSecondary");
                }

                &:last-child {
                  margin-bottom: 0;
                }

                .left {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  .icon {
                    height: 40px;
                    width: 40px;
                  }
                  .appName {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 16px;
                    @include themify($themes) {
                      color: themed("primaryTextColor");
                    }
                  }
                }
                .buttonPreview {
                  background: #81b9f9;
                  border-radius: 8px;
                  border: none;
                  outline: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  padding: 12px 23px;
                  @include themify($themes) {
                    background: themed("primaryBlue");
                  }

                  .buttonIcon {
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }
      }
      .outerPaddingContainer {
        width: 46%;
        padding: 0 0 0 50px;
        .preview {
          border-radius: 8px;
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          @include themify($themes) {
            background: themed("backgroundShade");
          }

          .titleText {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }
          .innerCard {
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 22px;

            @include themify($themes) {
              color: themed("primaryBlue");
              background: themed("secondaryBlue");
            }
          }
          .copyToClipboard {
            display: flex;
            align-items: center;
            cursor: pointer;

            .copyToClipboardIcon {
              margin-right: 10px;
            }

            .copyText {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              transition: 0.2s all ease-in-out;
              @include themify($themes) {
                color: themed("primaryBlue");
              }
            }
          }
        }
      }
    }
    .horizontalLine {
      width: 100%;
      height: 1px;
      background-color: #adaeb6ac;
      margin: 30px 0;
    }
    .infoCard {
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 4px 20px;
      box-sizing: border-box;
      margin-bottom: 30px;

      @include themify($themes) {
        background: themed("secondaryYellow");
      }

      .infoIcon {
        margin-right: 16px;
      }
      .infoText {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        padding: 6px;
        @include themify($themes) {
          color: themed("primaryYellow");
        }

        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: -24px 0 40px 0;

      .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 6px;
      }
      .input {
        border-radius: 8px;
        border: none;
        outline: none;
        padding: 20px;
        width: 400px;

        @include themify($themes) {
          color: themed("inputFieldText");
          background: themed("primaryInputFieldBackground");
        }

        &::placeholder {
          color: #adaeb6;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #adaeb6;
        }

        &::-ms-input-placeholder {
          color: #adaeb6;
        }
      }
    }
  }
}
