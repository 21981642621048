@import "../variables/theme-variables.scss";

.orderPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 210px);
  height: 100%;

  @include themify($themes) {
    color: themed("primaryTextColor");
  }

  .ordersContainer {
    flex: 1;
    height: 80%;
    width: 100%;

    .order {
      border-radius: 8px;
      width: 100%;
      margin-bottom: 16px;
      padding: 20px 30px;
      box-sizing: border-box;

      transition: all 0.2s ease-in-out;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 20px;
        }

        .textArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .titleArea {
            display: flex;
            align-items: center;

            .title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;

              @include themify($themes) {
                color: themed("primaryTextColor");
              }
            }
          }
          .subtitle {
            margin-top: 6px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            max-width: 700px;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include themify($themes) {
              color: themed("secondaryTextColor");
            }
          }
        }
      }

      @include themify($themes) {
        background-color: themed("secondaryBackgroundColor");
        border: themed("requestBorder");
        &:hover {
          background-color: themed("hoverPrimaryBackground");
        }
      }

      &:hover {
        border: 1px solid #f2d749;
      }
    }
  }

  .emptyOrderContainer {
    flex: 1;
    height: 60%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .emptyOrderComponent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .emptyIcon {
        margin-bottom: 10px;
      }

      .emptyTitle {
        margin-bottom: 10px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #c8cad2;
      }
      .emptySubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #c8cad2;
      }
    }
  }
}

// Smaller components ################################
.orderStatusArea {
  display: flex;
  align-items: center;

  .statusContainer {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  .statusText {
    margin-left: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    @include themify($themes) {
      color: themed("primaryTextColor");
    }
  }
}

.createdTime {
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  transition: 0.2s opacity ease-in-out;

  .clockIcon {
    margin-right: 7px;
  }
  .createdText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    @include themify($themes) {
      color: themed("secondaryTextColor");
    }
  }
}
.isEditing {
  opacity: 0;
}
// Smaller components ################################

.orderDetailsSidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 500px;
  z-index: 100;
  padding: 30px;
  box-sizing: border-box;
  transition: right 0.2s ease-in-out;
  display: flex;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  @include themify($themes) {
    background-color: themed("secondaryBackgroundColor");
  }

  .relativeContainer {
    position: relative;
    flex-direction: column;
    width: 100%;
  }

  .flexRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .closeArea {
      cursor: pointer;
    }
  }

  .headingArea {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      .icon {
        margin-right: 16px;
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        max-width: 230px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
      .editableContainer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        top: 50%;
        left: 60px;
        transform: translateY(-50%);
        z-index: 12;

        .selectInputArea {
          display: grid;
          grid-template-areas: "select";
          align-items: center;
          position: relative;
          border-radius: 8px;

          @include themify($themes) {
            background: themed("secondaryInputFieldBackground");
          }

          select,
          &::after {
            grid-area: select;
          }

          &::after {
            content: "";
            justify-self: end;
            width: 0.8rem;
            height: 0.5rem;
            background-color: #adaeb6;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            margin-right: 12px;
          }

          .select {
            width: 300px;
            height: 50px;
            border: none;
            font-weight: 600;
            font-size: 14px;
            border-radius: 8px;
            padding: 12px 24px 12px 12px;
            outline: none;
            appearance: none;
            cursor: pointer;
            animation: expandfromzero 0.3s ease-in-out;

            @keyframes expandfromzero {
              0% {
                width: 0;
              }
              100% {
                width: 300px;
              }
            }

            @include themify($themes) {
              background: themed("backgroundShadeSecondary");
              color: themed("primaryTextColor");
            }

            &:disabled {
              cursor: not-allowed;
              color: #adaeb6;
            }
          }
        }

        .cancel,
        .submit {
          margin-left: 6px;
          cursor: pointer;
          cursor: pointer;
          margin-left: 6px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .cancel {
          transform: scale(0.8);
        }
      }
      .editIcon {
        transform: scale(0.8);
        cursor: pointer;
        margin-left: 6px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .isEditing {
        opacity: 0;
      }
    }
  }

  .descriptionArea {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }

    .descriptionTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
    .descriptionText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      height: 46px;
      max-width: 100%;
      overflow: hidden;
      margin-top: 6px;
      max-height: 46px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }

    .primaryInputField {
      position: absolute;
      top: 50px;
      z-index: 12;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        background: themed("backgroundShadeSecondary");
        color: themed("primaryTextColor");
      }
    }
    .isEditing {
      opacity: 0;
    }
  }
  .workflowArea {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }

    .workflowTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
    .descriptionText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      max-width: 100%;
      margin-top: 6px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }
    .workflowCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 8px;
      margin-top: 16px;

      @include themify($themes) {
        background: themed("primaryBackgroundColor");
      }

      .icon {
        margin-right: 14px;
      }
      .workflowText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
    }
  }
  .priceBreakDownArea {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }

    .titles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }

    .subtitles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }
  }
  .totalArea {
    width: 100%;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themify($themes) {
      border-bottom: themed("borderPrimary");
    }
    .titles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
  }
  .buttonsArea {
    width: 100%;
    padding-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    .helpText {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }
  }
}

.hideSidebar {
  right: -500px;
}

.sidebarOverlay {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
  @include themify($themes) {
    background: themed("backgroundOverlay");
  }
}
