@import "../variables/theme-variables.scss";

.layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;

  .main-content {
    flex: 1;
    overflow: auto;
    padding: 120px 100px 0 100px;
    box-sizing: border-box;
    background-color: #3e3d40;
    position: relative;

    @include themify($themes) {
      background-color: themed("primaryBackgroundColor");
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #adaeb6;
    }
    &::-webkit-scrollbar-thumb {
      background: #575759;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #575759;
      transform: scaleX(0.9);
    }
  }
  .old-theme {
    background-color: #ffffff;
    padding: 100px 30px;
    box-sizing: border-box;

    .modal-content {
      background-color: red !important;
    }
  }
}
