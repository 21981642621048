@import "../variables/theme-variables.scss";

.appsOfferedPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto 20px auto;
  min-height: calc(100vh - 210px);

  @include themify($themes) {
    color: themed("primaryTextColor");
  }

  .tabSection {
    border-bottom: 1px solid #adaeb6;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .tabsArea {
      display: flex;

      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        padding: 10px 10px;
        margin-right: 6px;
        border-radius: 8px 8px 0px 0px;

        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        cursor: pointer;
      }
      .activeTab {
        @include themify($themes) {
          background: themed("navbarBackgroundColor");
          color: themed("primaryTextColor");
        }
      }
    }
    .appsCountArea {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #adaeb6;
    }
  }
  .appsSection {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    justify-items: left;
    grid-gap: 30px;
    width: 100%;

    .app {
      padding: 24px;
      width: 150px;
      height: 120px;
      border-radius: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      @include themify($themes) {
        background: themed("secondaryBackgroundColor");
      }

      .appName {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 30px 0 0 0;
        padding: 0;
        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }

      .isActive {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 28px;
        width: 28px;
      }

      img {
        height: 50%;
        width: 50%;
      }
    }
  }
  .appsSectionFlex {
    display: flex;
    justify-content: flex-start;
    grid-gap: 0;
    .app {
      margin-right: 14px;
    }
  }
  .emptyApp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 30px 0;

    .folderIcon {
      margin-bottom: 10px;
    }
    .emptyAppText {
      font-family: "Poppins";

      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      margin: 20px 0 20px 0;
      padding: 0;
    }
    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #adaeb6;
      margin: 0 0 30px 0;
      padding: 0;
      opacity: 0.5;
    }
    .button {
      cursor: pointer;
      outline: none;
      border: none;
      border-radius: 4px;
      padding: 12px 24px;

      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      @include themify($themes) {
        background: themed("primaryBlue");
      }
    }
  }
}

.appOverlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
}

.appModal {
  width: 700px;
  height: 360px;
  position: fixed;
  overflow: hidden;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  @include themify($themes) {
    background: themed("secondaryBackgroundColor");
  }

  @keyframes expand {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .titleArea {
    padding: 30px;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .whiteBackground {
        width: 70px;
        height: 70px;
        background-color: #fff;
        border-radius: 8px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          height: 50px;
          width: 50px;
        }
      }
      .textArea {
        color: #ffffff;

        .titleText {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
        }
        .subtitleText {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;

          opacity: 0.7;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }
  }
  .contentArea {
    padding: 30px;

    .contentText {
      margin: 0;
      padding: 0;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
  }
  .buttonArea {
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .callToAction {
      border-radius: 4px;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 12px 32px;

      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }

    .add {
      background: #1f90ff;
      @include themify($themes) {
        background: themed("primaryBlue");
      }
    }
    .remove {
      background-color: #fd7062;
    }
  }
}
