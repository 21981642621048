.createOrderPage {
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 210px);
  height: 100%;

  @include themify($themes) {
    color: themed("primaryTextColor");
  }
  .createOrderContainer {
    width: 100%;
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;
    @include themify($themes) {
      background: themed("secondaryBackgroundColor");
    }
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;

      @include themify($themes) {
        color: themed("primaryTextColor");
      }
    }
    .subtitle {
      margin-top: 6px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      width: 500px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include themify($themes) {
        color: themed("secondaryTextColor");
      }
    }

    .typesOfOrderContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 12px 0 24px 0;
      box-sizing: border-box;
      @include themify($themes) {
        border-bottom: themed("borderPrimary");
      }

      .orderType {
        cursor: pointer;
        min-width: 300px;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 12px 16px 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon {
          margin-right: 16px;
        }
        .type {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;

          @include themify($themes) {
            color: themed("primaryTextColor");
          }
        }
        @include themify($themes) {
          border: themed("borderPrimary");
        }
        &:hover {
          @include themify($themes) {
            border: themed("borderPrimary");
            background: themed("primaryBackgroundColor");
          }
        }
      }
      .selectedOrderType {
        @include themify($themes) {
          border: themed("buttonBorder");
        }
        &:hover {
          @include themify($themes) {
            border: themed("buttonBorder");
            background: themed("primaryBackgroundColor");
          }
        }
      }
    }
    .descriptionArea {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 24px 0 0 0;
      box-sizing: border-box;

      .descriptionTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 16px;

        @include themify($themes) {
          color: themed("primaryTextColor");
        }
      }
    }
  }
}
.confirmationModal {
  position: absolute;
  left: 50%;
  top: 48%;
  width: 500px;
  z-index: 100;
  padding: 30px;
  box-sizing: border-box;
  transition: right 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform: translate(-50%, -50%);

  @include themify($themes) {
    background-color: themed("secondaryBackgroundColor");
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    @include themify($themes) {
      color: themed("primaryTextColor");
    }
  }
  .subtitle {
    margin-top: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    @include themify($themes) {
      color: themed("secondaryTextColor");
    }
  }
  .buttonsArea {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .horizontalSpace {
      width: 16px;
      height: 16px;
    }
  }
}
