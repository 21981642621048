.users-container {
  position: relative;
  height: 100%;
  overflow: auto;
  background: #3e3d40;

  .content {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      height: 60px;
      margin-bottom: 30px;

      .titleText {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        color: #81b9f9;
      }

      .inviteUser {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background-color: #1f90ff;
        border-radius: 8px;
        border: none;
        outline: none;

        span {
          margin-left: 8px;
          color: #fff;

          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .mainArea {
      background: #2d2d31;
      border-radius: 16px;
      color: $white;
      padding-bottom: 30px;
      .errorArea {
        margin: 0 0 16px 30px;
      }

      .mainAreaTitle {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 13px;
        padding: 30px 0 0 30px;
        color: $white;
      }
      .mainAreaSubtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #adaeb6;
        margin-bottom: 32px;
        padding: 0 0 0 30px;
      }

      table {
        border-collapse: collapse;
        width: 100%;
        font-style: normal;

        thead {
          position: sticky;
          top: 0;
          background-color: #2d2d31;
          tr {
            th {
              font-weight: 800;
              font-size: 16px;
              line-height: 20px;
              color: $white;

              &:nth-child(1) {
                padding-left: 30px;
              }

              .tableHead {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 18px;
              }
            }
          }
        }

        tbody {
          tr {
            height: 80px;
            border-radius: 16px;
            border-top: 1px solid #afb5bb42;

            &:nth-child(1) {
              border-top: 1px solid #afb5bb42;
            }

            td {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: $white;

              &:nth-child(1) {
                padding-left: 30px;
              }

              .circularAvatarContainer {
                width: 48px;
                height: 48px;
                border-radius: 50%;
              }

              .statusCircle {
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 50%;
              }

              .moreOptions {
                cursor: pointer;
                height: 30px;
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .moreOptionPanel {
                  position: absolute;
                  width: 194px;
                  right: 40px;
                  top: 20px;
                  z-index: 2;
                  background: #424451;
                  border-radius: 8px;

                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: space-evenly;
                  padding: 20px 30px 20px 20px;

                  font-weight: 600;
                  font-size: 14px;
                  line-height: 18px;
                  color: #fff;

                  .menuItem {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    border-radius: 8px;
                    width: 100%;
                    height: 50px;
                    padding-left: 10px;

                    &:hover {
                      background-color: #767a94;
                    }

                    span {
                      margin-left: 8px;
                    }
                  }
                }
              }
            }

            &:hover {
              background: #38373a;
            }
          }
        }
      }
    }
  }
}

.overlay {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;

  .backDrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    height: 100vh;
    width: 100%;
    background-color: #000;
    opacity: 0.2;
  }

  .userModalContainer {
    position: relative;
    z-index: 12;

    .userModal {
      width: 500px;
      background: #3e3d40;
      border-radius: 16px;
      padding: 30px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
        0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
      animation: expand 0.2s ease-in-out;

      @keyframes expand {
        from {
          opacity: 0;
          transform: scale(0.5);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        margin: 0 0 30px 0;
      }

      .removeUser {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #fd7062;
        }
      }
      .spanTag {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #adaeb6;
        margin-bottom: 32px;
      }
      .heading {
        font-weight: 800;
        font-size: 24px;
        line-height: 31px;
        color: $white;
      }
      .subHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: $white;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 18px 0px;
      }
      .photoUploadContainer {
        width: 100%;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        position: relative;

        .editText {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 150px;
          width: 150px;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          cursor: pointer;

          font-weight: 800;
          font-size: 20px;
          line-height: 26px;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s linear;
          &:hover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 800;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: #fff;
            background-color: #000;
            opacity: 0.5;
          }
        }

        .dashedCircle {
          height: 150px;
          width: 150px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='lightgrey' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;

          .imageContainer {
            object-fit: cover;
            overflow: hidden;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
          }
          .uploadText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 800;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: #adaeb6;
          }

          .cameraIcon {
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 50%;

            width: 34px;
            height: 35px;
            background-color: #fff;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .imageEditContainer {
          height: 136px;
          width: 136px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;
          .editText {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 136px;
            width: 136px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            cursor: pointer;

            font-weight: 800;
            font-size: 20px;
            line-height: 26px;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s linear;
            &:hover {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 800;
              font-size: 20px;
              line-height: 26px;
              text-align: center;
              color: #fff;
              background-color: #000;
              opacity: 0.5;
            }
          }
          .cameraIcon {
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 50%;

            width: 34px;
            height: 35px;
            background-color: #fff;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .label {
        color: $white;
        margin: 4px 0 8px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
      input {
        background: #575759;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        height: 50px;
        padding: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        outline: none;

        color: $white;
        margin-bottom: 18px;

        &::placeholder {
          color: #adaeb6;
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: #adaeb6;
        }

        ::-ms-input-placeholder {
          color: #adaeb6;
        }
      }
      .disabled {
        color: #adaeb6;
        cursor: not-allowed;
      }

      button {
        margin-top: 18px;
        border-radius: 8px;
        padding: 20px;
        outline: none;
        border: none;
        color: #fff;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
      }

      .submitButton {
        background: #1f90ff;
        width: 100%;
        color: #fff;
      }
      .cancelButton {
        background: #e3e3e6;
        width: 100%;
        color: #424451;
      }
      .remove {
        background: #1f90ff;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
      }
      .cancel {
        background: #e3e3e6;
        font-weight: 600;
        font-size: 16px;
        color: #424451;
        margin-right: 14px;
      }
    }
  }
}
