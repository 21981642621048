.my-app-integration {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #3e3d40;
  color: #fff;

  form {
    display: flex;
    flex-direction: column;
    width: 350px;
    background: #2d2d31;
    padding: 75px 50px;
    border-radius: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    label {
      font-size: 14px;

      &:nth-of-type(2) {
        margin-top: 20px;
      }
    }
    input {
      border: none;
      padding: 15px 20px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      outline: none;
      border: none;
    }
    button {
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding: 15px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      color: #fff;
      background: #1f90ff;
      border: none;

      img {
        height: 35px;
        background: #fff;
        border-radius: 4px;
      }
      span {
        margin-left: 10px;
      }
    }
  }
}
