@import "./variables/theme-variables.scss";

@mixin login-left-container {
  width: 50%;
  background: center / cover url("../assets/img2/leftImage.svg") no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  .transparent {
    width: 75%;
    height: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $white;
    box-sizing: border-box;
    padding: 0 40px;
    background: transparent;

    p:nth-of-type(1) {
      font-size: 40px;
      font-weight: 800;
      margin-bottom: 24px;
      line-height: 50px;
    }
  }
}

@mixin login-input-field {
  border-radius: 8px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #575759;

  input {
    outline: none;
    border: none;
    font-size: 14px;
    margin-left: 18px;
    width: 100%;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: $white;
    background-color: #575759;

    &::placeholder {
      color: #c8cad2;
    }
  }

  img {
    width: 20px;
  }
}

@mixin custom-checkbox {
  appearance: none;
  background-color: #575759;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 2px solid #adaeb6;
  border-radius: 4px;

  display: grid;
  place-content: center;
  transition: 0.2s all ease-in-out;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    box-shadow: inset 1em 1em #adaeb6;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:hover {
    border: 2px solid $light-gray;
  }
}

@mixin login-btn {
  display: block;
  color: $white;
  background: $blue;
  padding: 20px;
  width: 100%;
  margin-top: 14px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 800;
  border: none;
  cursor: pointer;
}

@mixin sidebar-rows {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: $white;
  height: 5vh;
  overflow: hidden;

  img,
  .icon {
    padding: 0 12px 0 25px;
    height: 24px;
    width: 24px;
  }
  .title {
    margin: 18px;
    font-weight: 600;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0.5;
  }
  .counter {
    height: 30px;
    width: 50px;
    background: $gray-rgba-20;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .active {
    color: $menu-blue;
    font-weight: 700;
    opacity: 1;
  }
  .active-counter {
    color: $menu-blue;
    background: $blue-rgba-20;
    font-weight: 800;
  }
  .active-icon {
    filter: invert(55%) sepia(80%) saturate(602%) hue-rotate(191deg) brightness(113%) contrast(95%);
  }
}

@mixin custom-select($width) {
  margin-top: 6px;
  width: $width;

  .button {
    box-sizing: border-box;
    padding: 21px 20px;
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    background: #fff;

    span {
      color: $light-gray;
      font-size: 14px;
      font-weight: 600;
    }
    img {
      height: 24px;
      width: 24px;
    }
    .dropdown {
      width: 100%;
      max-height: 363px;
      overflow: auto;
      position: absolute;
      background: #fff;
      left: 0;
      top: 75px;
      border-radius: 8px;
      box-shadow: 0px 4px 24px rgba(129, 131, 143, 0.15);
      box-sizing: border-box;
      padding-top: 24px;

      .option {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: $blue-rgba-20;
        }
        img {
          padding-left: 20px;
          height: 40px;
          width: 40px;
        }
        span {
          color: $dark;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .divider {
        height: 0px;
        margin: 0 30px;
        border-bottom: 1px solid $silver-rgba-30;
      }
    }
  }
  .button-active {
    border: 1px solid $blue;
  }
}

@mixin steps-header {
  background: #fff;
  padding: 30px 30px 20px 30px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  .title {
    color: $dark;
    font-size: 28px;
    font-weight: 800;
  }
  .subtitle {
    color: $gray;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
  }
}

@mixin steps-footer {
  color: $gray;
  font-size: 14px;
  font-weight: 600;
  margin-top: 174px;
  display: flex;
  align-items: center;

  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}

@mixin custom-switch {
  color: $dark;
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  display: flex;
  align-items: center;

  label {
    position: relative;
    display: flex;
    height: 18px;
    width: 34px;
    margin-right: 10px;

    input {
      opacity: 0;
      height: 0;
      width: 0;
      &:checked + span {
        background: $blue;
      }
      &:checked + span::before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $light-gray;
      border-radius: 34px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        height: 14px;
        width: 14px;
        background: $white;
        border-radius: 14px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}

@mixin darkmode-switch {
  color: $dark;
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  display: flex;
  align-items: center;

  label {
    position: relative;
    display: flex;
    height: 18px;
    width: 70px;
    margin-right: 10px;

    input {
      opacity: 0;
      height: 0;
      width: 0;
      &:checked + span {
        // background: $blue;
        @include themify($themes) {
          background: themed("themeSwitchBackground");
        }
      }
      &:checked + span::before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 34px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      cursor: pointer;

      @include themify($themes) {
        background: themed("themeSwitchBackground");
      }

      &::before {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        @include themify($themes) {
          background: themed("themeSwitchHandle");
        }
      }
    }
  }
}
