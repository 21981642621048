.profilePage {
  font-family: "Poppins";
  color: #ffffff;
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;

  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #adaeb6ac;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #adaeb6;
  }
  &::-webkit-scrollbar-thumb {
    background: #575759;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #575759;
    transform: scaleX(0.9);
  }

  .mainArea {
    flex: 1;
    display: flex;
    margin-bottom: 20px;

    .mainForm {
      flex: 1;
      border-left: 1px solid #afb5bb73;
      padding: 30px 0 0 30px;
      margin: 0 0 30px 0;

      .content {
        box-sizing: border-box;
        background-color: #575759;
        overflow: hidden;
        border-radius: 8px;
        padding: 30px;
        box-sizing: border-box;
        transition: 0.2s ease-in-out;
        height: 300px;

        .titleArea {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;

          .titleText {
            font-weight: 600;
            font-size: 20px;
            line-height: 16px;
            color: #adaeb6;
          }

          .profileMainActions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            transition: all 0.3s ease-in-out;

            button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 12px 24px;
              border-radius: 8px;
              outline: none;
              border: none;
              font-weight: 800;
              font-size: 14px;
              line-height: 18px;
              cursor: pointer;
            }

            .editButton {
              font-weight: 800;
              font-size: 14px;
              line-height: 18px;
              border-radius: 8px;
              padding: 12px 24px;
              outline: none;
              border: none;
              cursor: pointer;
              background-color: #adaeb6;
              color: #424451;
            }

            .cancelButton {
              background: #adaeb6;
              color: #424451;
              margin: 0 16px;
            }
            .saveButton {
              background: #1f90ff;
              color: #fff;

              &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
              }
            }
          }
        }
        .mainArea {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ImageContainer {
            border-radius: 50%;
            border: 7px solid #fff;
            position: relative;

            height: 136px;
            width: 136px;
            box-shadow: 0px 4px 6px rgba(66, 68, 81, 0.15);
            margin-right: 30px;

            .image {
              height: 100%;
              width: 100%;
              object-fit: fill;
              border-radius: 50%;
            }

            .profileCamera {
              position: absolute;
              height: 40px;
              width: 40px;
              bottom: 0;
              right: 0;
              z-index: 2;

              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;
            }
            .disabled {
              cursor: not-allowed;
            }
          }
          .textContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .userName {
              font-weight: 800;
              font-size: 24px;
              color: $white;
            }
            .emailText {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              opacity: 0.5;
              padding: 0;
              margin: 8px 0 0 0;
            }
          }
        }
        .profileBody {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 30px;
          .inputGroup {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;

            .labelTitle {
              width: 140px;
              color: $white;
              margin-right: 20px;
            }
            .inputArea {
              width: 360px;
            }

            input {
              background: #4d4d51;
              border: none;
              box-sizing: border-box;
              border-radius: 8px;
              width: 100%;
              height: 50px;
              padding: 20px;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              outline: none;
              color: $white;
              max-width: 500px;

              &:disabled {
                color: #4d4d51;
                cursor: not-allowed;
              }

              &::placeholder {
                color: #adaeb6;
                opacity: 1;
              }

              :-ms-input-placeholder {
                color: #adaeb6;
              }

              ::-ms-input-placeholder {
                color: #adaeb6;
              }
            }
            .disabled {
              color: #adaeb6;
              cursor: not-allowed;
            }
          }

          .emailInputGroup {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding-bottom: 20px;
            position: relative;

            .labelTitle {
              margin-bottom: 30px;
              width: 140px;
              color: $white;
              text-align: left;
              margin-right: 20px;
            }
            .inputArea {
              width: 360px;
              position: relative;

              .icon {
                position: absolute;
                top: 25px;
                left: 20px;
                transform: translate(0, -50%);
              }
            }

            .emailInput {
              background: #4d4d51;
              border: none;
              box-sizing: border-box;
              border-radius: 8px;
              width: 100%;
              height: 60px;
              padding: 20px 20px 20px 50px;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              outline: none;
              color: #adaeb6;
              max-width: 500px;

              &::placeholder {
                color: #adaeb6;
                opacity: 1;
              }

              :-ms-input-placeholder {
                color: #adaeb6;
              }

              ::-ms-input-placeholder {
                color: #adaeb6;
              }
            }
            .disabled {
              color: #adaeb6;
              cursor: not-allowed;
              height: 50px;
            }

            .text-secondary {
              font-weight: 500;
              font-size: 12px;
              color: #adaeb6;
            }
          }
          .selectInputArea {
            background: #4d4d51;
            display: grid;
            grid-template-areas: "select";
            align-items: center;
            position: relative;
            border-radius: 8px;

            select,
            &::after {
              grid-area: select;
            }

            &::after {
              content: "";
              justify-self: end;
              width: 0.8rem;
              height: 0.5rem;
              background-color: #adaeb6;
              clip-path: polygon(100% 0%, 0 0%, 50% 100%);
              margin-right: 12px;
            }

            .select {
              width: 360px;
              height: 50px;
              background: #4d4d51;
              border: none;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              border-radius: 8px;
              padding: 12px 24px 12px 12px;
              outline: none;
              appearance: none;
              cursor: pointer;
              color: $white;

              &:disabled {
                cursor: not-allowed;
                color: #adaeb6;
              }
            }
          }
        }
      }
      .biggerHeight {
        height: 100%;
      }
    }
  }
}
