/*---------------------------------------------
     Portfolio Gallery
----------------------------------------------*/

.masonry-gallery {
  margin-left: -15px;
  list-style-type: none;
  padding: 0px;
}
.masonry-gallery li {
  padding: 15px;
}
