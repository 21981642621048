.history-container {
  display: flex;
  flex-direction: row;
  background: #fafafb;
  height: 100%;
  position: relative;
  min-height: calc(100vh - 170px);

  .sidebar {
    height: 100%;
    width: 25%;
    border-right: 1px solid $silver-rgba-30;
    box-sizing: border-box;
    padding: 60px 40px 0 40px;

    .title {
      color: $dark;
      font-size: 32px;
      font-weight: 800;
      margin-bottom: 32px;
    }
    .tab {
      min-height: 60px;
      box-sizing: border-box;
      padding: 20px;
      color: $dark;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background: $blue-rgba-20;
      }
    }
    .active {
      background: $blue-rgba-20;
      color: $blue;
      font-size: 16px;
      font-weight: 800;
    }
  }
  .main-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 60px 40px 0 30px;
    overflow: auto;

    .header {
      display: flex;
      align-items: center;
      color: $dark;
      font-weight: 800;

      .title {
        font-size: 32px;
        margin-right: auto;
      }
      .filter-container {
        width: 19%;
        margin-right: 10px;
        position: relative;

        &:focus .calendar {
          display: flex;
        }
        &:focus .list {
          display: flex;
        }
        .header {
          width: 100%;
          border: 1px solid $light-gray;
          border-radius: 8px;
          padding: 4px;
          box-sizing: border-box;
          background: $white;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            height: 32px;
          }
          span {
            color: $gray;
            font-size: 14px;
            font-weight: 600;
            margin-left: 16px;
          }
        }
        .calendar {
          position: absolute;
          top: 45px;
          left: -200px;
          width: 732px;
          background: $white;
          border-radius: 10px;
          display: none;
          color: $dark;
          font-size: 14px;
          font-weight: 600;
          box-shadow: 0px 0px 5px $gray;
          overflow: hidden;

          .left-container {
            width: 17%;
            border-right: 1px solid $silver-rgba-30;

            div {
              width: 100%;
              height: 39px;
              padding: 0 16px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            .selector-active {
              background: $blue-rgba-20;
            }
          }
          .right-container {
            display: flex;
            flex-direction: column;
            flex: 1;

            .calendar-header {
              padding: 13px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid $silver-rgba-30;

              .first-month,
              .second-month {
                display: flex;
                align-items: center;
                flex: 1;

                img {
                  height: 18px;
                  cursor: pointer;
                }
                .single {
                  height: 11px;
                  margin: 0 5px;
                }
                span {
                  margin-left: auto;
                  margin-right: auto;
                  font-size: 16px;
                }
              }
            }
            .calendar-body {
              display: flex;
              justify-content: space-between;

              table {
                border-spacing: 11px;
                border-collapse: separate;

                td {
                  height: 28px;
                  width: 28px;
                  text-align: center;
                  font-weight: 300;
                  cursor: pointer;
                }
                .td-today {
                  border: 1px solid $blue;
                  border-radius: 10px;
                }
                .td-between {
                  background: $blue-rgba-20;
                }
                .td-selected {
                  background: $blue;
                  border-radius: 10px;
                  color: $white;
                }
              }
            }
          }
        }
        .list {
          position: absolute;
          top: 45px;
          left: 0;
          width: 100%;
          background: $white;
          border-radius: 10px;
          display: none;
          flex-direction: column;
          color: $dark;
          font-size: 14px;
          font-weight: 600;
          box-shadow: 0px 0px 5px $gray;
          overflow: hidden;

          div {
            height: 58px;
            display: flex;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;
          }
          div + div {
            border-top: 1px solid $silver-rgba-30;
          }
          .list-active {
            background: $blue-rgba-20;
          }
        }
      }
      .failed-items {
        display: flex;
        align-items: center;

        .switch-container {
          @include custom-switch();
        }
      }
    }
    .page-selector {
      margin: 10px 0 10px auto;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
      span {
        color: $dark;
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px;
      }
    }
    .accordion {
      margin: 16px 0;
      background: $white;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        align-items: center;
        padding: 20px 30px 20px 20px;
        cursor: pointer;

        img {
          height: 40px;
        }
        .column {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          border-right: 1px solid $silver-rgba-30;

          .title {
            font-size: 12px;
            font-weight: 600;
            color: $gray;
            margin-bottom: 7px;
          }
          .sub-title {
            font-size: 14px;
            font-weight: 600;
            color: $dark;
          }
        }
        .apps {
          border-radius: 8px;
          padding: 15px 20px;
          display: flex;
          align-items: center;

          img {
            &:first-child,
            &:last-child {
              height: 40px;
            }
            &:nth-child(2) {
              height: 24px;
              width: 24px;
              margin: 0 20px;
            }
          }
        }
        .re-run {
          margin-left: auto;
          display: flex;
          align-items: center;
          margin-right: 30px;

          img {
            height: 20px;
            width: 20px;
          }
          span {
            margin-left: 10px;
            color: $blue;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .expand {
          height: 24px;
        }
        .rotated {
          transform: rotate(180deg);
        }
      }
      .body {
        border-top: 1px solid $silver-rgba-30;
        padding: 23px 20px 33px;
        display: flex;
        align-items: center;

        .data {
          border: 1px solid $silver-rgba-30;
          border-radius: 8px;
          padding: 10px;
          display: flex;
          flex-direction: column;

          pre {
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
          }
        }
        img {
          height: 24px;
          width: 24px;
          margin: 0 16px;
        }
      }
      .collapsed {
        display: none;
      }
    }
    .expanded {
      box-shadow: 0px 0px 1px 1px $blue;
    }
  }
}
