/*-----------------------------------------------------------
    Display Code Wrappers
----------------------------------------------------------*/

kbd {
  color: #fff;
  background-color: #555;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.25);
  -moz-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.25);
  box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.25);
}
pre {
  color: #555555;
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: $danger-color;
  background-color: #f9f2f4;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
