.historyPage {
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  max-width: 1240px;
  margin: 0 auto;
  min-height: calc(100vh - 170px);

  .navigationArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    border-bottom: 1px solid #adaeb6ac;
    margin-bottom: 16px;

    .backIconContainer {
      cursor: pointer;
      height: 20px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navigationText {
      margin-right: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
    }
  }
}
