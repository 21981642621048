#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: gray;
  text-align: center;
  width: 100%;
  padding: 0;
}

#progressbar .active {
  color: #001d43;
  cursor: pointer;
}
#progressbar .active svg {
  color: #ffffff;
  background: #001d43;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 20%;
  float: left;
  position: relative;
  font-weight: 400;
  z-index: 1;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: gray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar svg {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  color: #ffffff;
  background: gray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 10px;
}

#progressbar .action-icon {
  width: 10px;
  height: 10px;
  line-height: 0px;
  display: block;
  color: #ffffff;
  background: transparent;
  border-radius: 0%;
  margin: 5px 0;
  padding: 0;
}

#progressbar i.active:before,
#progressbar i.active:after {
  background: #001d43;
}

.progress {
  height: 20px;
  width: 100%;
}
.fixed-action-bar {
  width: calc(100% - 240px);
  position: fixed;
  z-index: 1030;
}
/* For mobile view, turn off the progress bar.  */
@media only screen and (max-width: 768px) {
  #progressbar {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .progress {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .fixed-action-bar {
    width: calc(100% - 60px);
  }
}

.progress-bar {
  background-color: #001d43;
}

.btn-default.focus,
.btn-default:focus {
  background: #fff !important;
  color: #333333 !important;
}
.btn-default {
  border-color: #ccc !important;
  background: #fff !important;
  color: #333333 !important;
}
.btn-default:hover {
  color: white !important;
  background: #001d43 !important;
}
.btn-default:hover,
.btn-primary:hover {
  background: #001d43 !important;
}
