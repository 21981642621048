.aiPageLayout {
  height: 100%;
  display: flex;
  background: #3e3d40;
  overflow: auto;
  position: relative;

  .leftContainer {
    width: 45%;
    height: 100%;
    position: relative;
    padding: 0 20px 0 2px;

    .header {
      .title {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        color: #81b9f9;
        margin-bottom: 26px;
      }
    }
    .floatingActionButton {
      position: absolute;
      bottom: 10px;
      right: 50px;
      border-radius: 50px;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 16px rgba(94, 166, 237, 0.5);
      z-index: 11;
      background-color: #1f90ff;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  .rightContainer {
    width: 55%;
    height: 100%;
    display: flex;
    position: relative;

    .verticalLine {
      background-color: #575759;
      margin-right: 30px;
      width: 1px;
      height: 100%;
    }

    .emptyContainer {
      width: 100%;
      background: #575759;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .notSelectedText {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #adaeb664;
      }
    }
  }
  .processBody {
    display: flex;
    flex-direction: column;
    height: 80%;
    padding-right: 10px;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #adaeb6;
    }
    &::-webkit-scrollbar-thumb {
      background: #575759;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #575759;
      transform: scaleX(0.9);
    }

    .emptyAI {
      display: flex;
      flex-direction: column;
      align-items: center;

      .onBoarding {
        width: 100%;
        background-color: #2d2d31;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;

        .row {
          display: flex;
          align-items: center;
          .circularStatus {
            width: 8px;
            height: 8px;
            background: #8ce28b;
            border-radius: 100%;
            margin-right: 8px;
            margin-left: 20px;
          }
          .info {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #adaeb6;
          }

          .whiteText {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            margin: 0 10px 0 16px;
          }
          .blueText {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #81b9f9;
            margin-right: 20px;
          }
        }
      }

      .welcomeText {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 10px;
      }

      button {
        margin-top: 20px;
        background: $blue;
        padding: 11px 24px;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        border: none;
        cursor: pointer;

        img {
          height: 18px;
          width: 18px;
        }
        span {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 800;
          line-height: 18px;
          vertical-align: top;
        }
      }
    }

    .body {
      flex: 1;
      height: 100%;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: #e3e3e67b;
      }
      &::-webkit-scrollbar-thumb {
        background: #adaeb67e;
        border-radius: 8px;
      }
    }
  }
  .workflow-card {
    width: 100%;
    padding: 30px 30px 30px 10px;
    box-sizing: border-box;
    background: #3e3d40;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #afb5bb49;

    &:hover {
      background: #38373a;
    }

    &:last-child {
      margin-bottom: 32px;
    }

    .workflow-card-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .title {
        color: $white;
        font-size: 24px;
        font-weight: 800;
        margin-right: 12px;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .menu-container {
        position: relative;

        .menu {
          position: absolute;
          height: 106px;
          width: 203px;
          top: 0;
          right: -20px;
          z-index: 2;
          background: #424451;
          border-radius: 8px;

          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 20px;

          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #fff;

          .update,
          .delete {
            display: flex;
            align-items: center;
            cursor: pointer;

            border-radius: 8px;
            width: 100%;
            height: 50px;
            padding-left: 10px;

            &:hover {
              background-color: #767a94;
            }

            span {
              margin-left: 8px;
            }
          }
        }
        .menu-bottom {
          top: -106px;
        }
      }
      .menu-icon {
        height: 24px;
        width: 24px;
        cursor: pointer;

        &:focus + .menu {
          visibility: visible;
        }
      }
      .switch-container {
        @include custom-switch();
      }
    }
    .sub-title {
      width: 100%;
      color: $gray;
      font-size: 14px;
      font-weight: 600;
      margin-top: 13px;
    }
    .spaceBetweenRow {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .processDetails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;

        .actionsContainer {
          display: flex;
          flex-direction: column;
          margin-right: 30px;

          .row {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #adaeb6;

            .icon {
              margin-right: 10px;
            }
          }

          .text {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #adaeb6;
            padding: 6px 0;
          }
        }

        .priceContainer {
          display: flex;
          flex-direction: column;
          margin: 0 20px;

          .row {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #adaeb6;

            .icon {
              margin-right: 10px;
            }
          }

          .text {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #adaeb6;
            padding: 6px 0;
          }
        }
        .circularStatus {
          width: 8px;
          height: 8px;
          background: #8ce28b;
          border-radius: 100%;
          margin-right: 8px;
        }
        .statusText {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #adaeb6;
        }
      }
      .pricingStructure {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        .pricing {
          font-weight: 700;
          font-size: 24px;
          color: #adaeb6;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          text-align: right;
          color: #adaeb6;
          padding: 6px 0;
        }
      }
    }
    .workflow-card-body {
      margin-top: 24px;
      display: flex;
      align-items: center;

      .app-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        border: 1px solid $platinum;
        border-radius: 8px;
        align-items: flex-start;

        img {
          width: 60px;
          margin: 5px 5px 0 5px;
        }
        .title {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
          margin: 0 20px;
        }
        .trigger {
          color: $gray;
          font-size: 12px;
          font-weight: 600;
          margin: 6px 20px 40px 20px;
        }
      }
      .right-arrow {
        height: 24px;
        width: 24px;
        margin: 0 24px;
      }
    }
  }
  .pending {
    background: #3e3d40;
    cursor: not-allowed;

    &:hover {
      background: #38373a;
    }
  }
}

.backdropOverlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  z-index: 10;
}

.deleteModal {
  position: fixed;
  width: 440px;
  background-color: #3e3d40;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  @keyframes expand {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .title {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: $white;
    margin-bottom: 18px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $white;
    margin-bottom: 18px;
    span {
      color: #1f90ff;
      text-decoration: underline;
    }
    .noUnderLine {
      text-decoration: none;
    }
  }
  .contactDetails {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #adaeb6;
  }
  .row {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .icon {
      margin-right: 8px;
    }
  }

  .actionArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-top: 18px;
      border-radius: 8px;
      padding: 20px 30px;
      outline: none;
      border: none;
      color: #fff;
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
    }

    .submitButton {
      background: #1f90ff;
      color: #fff;
    }

    .cancelButton {
      background: #e3e3e6;
      color: #424451;
      margin-right: 14px;
    }
  }
}

.updateModal {
  position: fixed;
  width: 440px;
  background-color: #fff;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;

  @keyframes expand {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.remarksModal {
  position: fixed;
  width: 440px;
  background-color: #3e3d40;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.2s ease-in-out;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

  @keyframes expand {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #adaeb6;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 24px;

    .icon {
      margin-right: 10px;
    }
  }
  .contactDetails {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #adaeb6;
  }
  .contactRow {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .icon {
      margin-right: 8px;
    }
  }
  .backButton {
    background: #1f90ff;
    color: #fff;
    margin-top: 26px;
    border-radius: 8px;
    padding: 20px 30px;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    width: 100%;
  }
}
