.confirmation-modal {
  width: 440px;
  height: 191px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(227, 229, 235, 0.75);
  border-radius: 10px;

  h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: #424451;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #424451;
  }

  .confirmation-buttons {
    button:first-child {
      background-color: $platinum;
    }
    button:last-child {
      background-color: $blue;
      color: $white;
    }
  }
}
