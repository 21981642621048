.content {
  .notEditableContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;

      .title {
        padding: 0;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        opacity: 0.5;
      }
      .companyName {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #ffffff;
      }
      .timezoneText {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
      }
    }
  }
}
