@import "../variables/theme-variables.scss";

.footer {
  height: 70px;
  width: 100%;
  background: #3e3d40;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: center;
  box-sizing: border-box;

  @include themify($themes) {
    background: themed("primaryBackgroundColor");
  }

  .footerText {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.8;

    @include themify($themes) {
      color: themed("secondaryTextColor");
    }
  }
}
