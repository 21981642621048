/*---------------------------------------------
     jVector Maps
----------------------------------------------*/

.jvectormap-container {
  width: 100%;
  height: 100%;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 16px;
  height: 16px;
}
.tab-pane .jvectormap-container svg {
  width: 100%;
  height: 100%;
  min-height: 500px;
}
body .jvectormap-container svg path {
  fill: #dddddd;
}
body .jvectormap-container svg path:hover {
  fill: #bdbdbd;
}
