.tableCol {
  border-left: 1px solid rgba(224, 224, 224, 1);
}
.tableRow {
  border: 2px solid grey;
}
.tableCell {
  max-width: 100px !important;
  word-break: break-word;
  border: none !important;
}
.scroll {
  border: 2px solid;
  padding: 20px;
  width: 200px;
  height: 200px;
  max-height: inherit;
  resize: both;
  overflow: auto;
}
.card {
  margin-bottom: 10px !important;
}
.cardBody {
  line-height: 1;
  padding-top: -10px;
  padding-bottom: -10px;
}
